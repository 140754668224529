import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FusionConfigService } from '@exxat/fusion/core';

import { FuseSidebarService } from '../../../components/sidebar/sidebar.service';
import { LayoutService } from '../../vertical/layout-1/layout-1.service';
import { ManifoldPanelService } from './manifold-panel.service';
import { ManifoldPanelContentDirective } from './manifold-panel-content.directive';
import { ManifoldPanel } from './manifold-panel';

@Component({
  selector: 'manifold-panel',
  templateUrl: './manifold-panel.component.html',
  styleUrls: ['./manifold-panel.component.scss']
})
export class ManifoldPanelComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ManifoldPanelContentDirective, { static: false })
  manifoldPanelContentRefHost: ManifoldPanelContentDirective;
  @ViewChild('sidebar', { static: false }) sidebar: ElementRef;
  @ViewChild('primaryAction', { static: false }) primaryAction: ElementRef;
  @ViewChild('manifoldPanelTitle', { static: false }) manifoldPanelTitle: ElementRef;

  private _unsubscribeAll: Subject<any>;
  fusionConfig: any;
  panelName: string;
  manifoldPanelInputs: ManifoldPanel;
  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _fusionConfigService: FusionConfigService,
    private _manifoldPanelService: ManifoldPanelService,
    public _layoutService: LayoutService,
    private _renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.manifoldPanelSettings();
    this._fusionConfigService.uiSettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fusionConfig = config;
      });
    this.manifoldPanelInputs = this._layoutService.manifoldPanelInputs;
  }

  async ngAfterViewInit(): Promise<void> {
    this._layoutService.manifoldPanelTitleMap.set(
      this._layoutService.manifoldPanelInstances,
      this.manifoldPanelTitle
    );
    this._layoutService.manifoldPanelSizeMap.set(
      this._layoutService.manifoldPanelInstances,
      this._layoutService.manifoldPanelInputs.size
    );
    this._layoutService.manifoldPanelSidebarMap.set(
      this._layoutService.manifoldPanelInstances,
      this.sidebar['_elementRef'].nativeElement
    );
    if (!!this.primaryAction)
      this._layoutService.manifoldPanelPrimaryActionMap.set(this._layoutService.manifoldPanelInstances, this.primaryAction['_elementRef'].nativeElement);
    this._layoutService.manifoldPanelComponentRefMap.set(
      this._layoutService.manifoldPanelInstances,
      this.manifoldPanelContentRefHost
    );
    this._layoutService.manifoldPanelContentRef = this.manifoldPanelContentRefHost;
    this._fuseSidebarService.getSidebar(this.panelName).open();
    if (this._layoutService.componentType != null)
      await this._manifoldPanelService.createComponent(
        this._layoutService.componentType,
        this._layoutService.manifoldPanelContentRef,
        true
      );
    if (this._layoutService.manifoldPanelInstances > 1) {
      this._manifoldPanelService.manageManifoldPanels();
    }
    this._renderer.addClass(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      this._layoutService.manifoldPanelInputs.size
    );

    this._changeDetectorRef.detectChanges();
  }

  manifoldPanelSettings() {
    this._layoutService.manifoldPanelInstances += 1;
    this.panelName = 'manifoldPanel_'.concat(
      this._layoutService.manifoldPanelInstances.toString()
    );
  }

  unfoldPanel() {
    if (this._layoutService.manifoldPanelInstances === 1) {
      this.clearManifoldPanelInstances();
    } else {
      this._manifoldPanelService.closePanel(this.panelName);
      this.renderPanelClasses();
    }
  }

  onRecordSave() {
    this._manifoldPanelService.onPrimaryAction();
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');

  }

  onRecordDelete() {
    this._manifoldPanelService.onSecondaryAction();
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');

  }

  onbackDropChange() {
    this.unfoldPanel();
  }

  clearManifoldPanelInstances() {
    this._manifoldPanelService.closeAllManifoldPanels();
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  renderPanelClasses() {
    this._renderer.removeClass(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      'drawer-xx-lg'
    );
    this._renderer.removeStyle(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      'z-index'
    );
    this._renderer.addClass(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      this._layoutService.manifoldPanelSizeMap.get(
        this._layoutService.manifoldPanelInstances
      )
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

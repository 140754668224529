type navigationItemType= | 'menu-item'
| 'menu-group'
| 'menu-collapsable'
| 'page-nav-item';
export interface NavigationItem {
  id: string;
  title: string;
  type:navigationItemType;

  icon?: string;
  order?:number;
  hidden?: boolean;
  url?: string;
  translate?:string;
  classes?: string;
  exactMatch?: boolean;
  openInNewTab?: boolean;
  function?: any;
  badge?: {
    title?: string;
    translate?: string;
    bg?: string;
    fg?: string;
  };
  children?: NavigationItem[];
}

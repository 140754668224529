import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation, Renderer2, ElementRef, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseSidebarService } from '../../../components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '../../../directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { DrawerService } from './drawer.service';
import { DrawerContentDirective } from './drawer-content.directive';
import { LayoutService } from '../../vertical/layout-1/layout-1.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../../../components/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrawerComponent implements OnInit, AfterViewInit, OnDestroy {

  sidebarFolded: boolean;
  drawerTitle: string;
  componentSelector: string;
  drawerSize: string;
  isFormValid: BehaviorSubject<boolean>;

  @ViewChildren(FusePerfectScrollbarDirective)
  private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

  @ViewChild(DrawerContentDirective, { static: false })
  refHost: DrawerContentDirective;

  // Private
  private _drawerViewScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   */
  constructor(
    private _drawerService: DrawerService,
    public _layoutService: LayoutService,
    private _fuseSidebarService: FuseSidebarService,
    private renderer: Renderer2,
    private el: ElementRef,
    private _confirmDialogService: ConfirmDialogService
  ) {
    // Set the defaults
    this.isFormValid = _layoutService.isFormValid$;
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the foldedChanged observable
    this._fuseSidebarService.getSidebar('drawer').foldedChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((folded) => {
        this.sidebarFolded = folded;
      });

    this._layoutService.drawerTitle
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        this.drawerTitle = response;
      });

    this._layoutService.drawerSize
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        if (!!response) {
          if (!!this.drawerSize) this.renderer.removeClass(this.el.nativeElement, this.drawerSize);
          this.drawerSize = response;
          this.renderer.addClass(this.el.nativeElement, response);
        }
      });
  }

  onRecordSave() {
    this._layoutService.isFormValid$.next(false);
    this._layoutService.showPrimaryActionSpinner$.next(true);
    this._drawerService.onPrimaryAction();
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');
  }

  onRecordDelete() {
    this._drawerService.onSecondaryAction();
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');
  }
  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this._drawerViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
      return directive.elementRef.nativeElement.id === 'messages';
    });
    this._layoutService.referenceHost = this.refHost;
    // ComponentUtils.referenceHost = this.refHost;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Fold the temporarily unfolded sidebar back
   */
  foldSidebarTemporarily(): void {
    if (this._layoutService.componentRef.instance?.fusionFormGroup?.dirty) {
      const message = `You have unsaved changes which will be lost if you navigate away. Are you sure you wish to discard these changes?`;
      this._confirmDialogService.open(message, { ok: 'Leave', cancel: 'Stay' });
      this._confirmDialogService.confirmDialogAction().subscribe(confirmed => {
        if (confirmed) {
          this._fuseSidebarService.getSidebar('drawer').toggleOpen();
        }
      });
    } else {
      this._fuseSidebarService.getSidebar('drawer').toggleOpen();
    }
    this._layoutService.exxatCloseDrawerAction$.next('foldSidebar');
  }

  /**
   * Unfold the sidebar temporarily
   */
  unfoldSidebarTemporarily(): void {
    this._fuseSidebarService.getSidebar('drawer').close();
  }

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpen(): void {
    this._fuseSidebarService.getSidebar('drawer').toggleOpen();
  }
}

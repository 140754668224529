import { Directive, Input, OnInit, Output, EventEmitter, HostListener, Type } from '@angular/core';

import { DrawerService } from '../../layout/components/drawer/drawer.service';
import { FuseSidebarService } from '../../components/sidebar/sidebar.service';
import { LayoutService } from '../../layout/vertical/layout-1/layout-1.service';

@Directive({ selector: '[exxatDrawerAction]' })
export class ExxatDrawerActionDirective implements OnInit {

  key = 'drawer';
  @Input() componentSelector: string = null;
  @Input() moduleId: string = null;
  @Input() caption: string = null;
  @Input() size: string = null;
  @Input() data: any = null;
  @Input() entityKey: any = null;
  @Input() primaryAction: string = null;
  @Input() secondaryAction: string = null;

  @Output() exxatDrawerAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _drawerService: DrawerService,
    private _layoutService: LayoutService,
    private _fuseSidebarService: FuseSidebarService
  ) {

   }

  ngOnInit(): void { }

  @HostListener('click', ['$event.target']) onClick() {
    this._layoutService.drawerTitle.next(this.caption != null && this.caption.replace(/\s/g, '').trim() !== '' ? this.caption : null);
    this.exxatDrawerAction.emit();
    this._fuseSidebarService.getSidebar(this.key).open();
    this._layoutService.drawerSize.next(this.size);
    this._drawerService.showDrawerContent(this.componentSelector, this.moduleId, this.data, this.entityKey, this.primaryAction, this.secondaryAction);
  }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Announcement } from '@exxat/publicwebsite/models';
import { AnnouncementApiClient } from '@exxat/publicwebsite/services';

@Injectable()
export class AnnouncementSandBox {
  constructor(private _announcementApiClient: AnnouncementApiClient) {}

  public getAnnouncements(
    tenantId,
    oucode,
    tenantName
  ): Observable<Announcement[]> {
    return this._announcementApiClient.getAnnouncement(
      tenantId,
      oucode,
      tenantName
    );
  }
  public getAnnouncement(
    tenantId,
    oucode,
    tenantName,
    id
  ): Observable<Announcement> {
    return this._announcementApiClient.getAnnouncementDetail(
      tenantId,
      oucode,
      tenantName,
      id
    );
  }
}

import { TokenModel } from './token.model';
export class User {
  Id?: string;
  Email?: string;
  UserName?: string;
  UserRoles?: any[];
  Token?: TokenModel;
}

export class UserInputModel {

  constructor(
    public userName: string,
    public password?: string,
    public grantType = 'password'){}

}

export class UserRefreshTokenModel {
  constructor(
    public refreshToken: string,
    public grantType = 'refresh_token') { }
}

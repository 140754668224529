import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../shared.module';

import { NavbarComponent } from './navbar.component';
import { NavbarVerticalStyle2Module } from './vertical/style-2.module';
// import {  FusionNavigationModule } from './../../../components/navigation/navigation.module';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        FuseSharedModule,
        NavbarVerticalStyle2Module
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSearchBarModule, FuseShortcutsModule } from '../../../components';

import { HeaderComponent } from './header.component';
import { MaterialModule } from '../../../material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TechnicalSupportPopupComponent } from './technical-support-popup/technical-support-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '../../../shared.module';
import { HelpCenterPopupComponent } from './help-center-popup/help-center-popup.component';
import { ConsentDetailsPopupComponent } from './consent-details-popup/consent-details-popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TechnicalSupportPopupComponent,
    HelpCenterPopupComponent,
    ConsentDetailsPopupComponent
  ],
  imports: [
    RouterModule,
    // MatButtonModule,
    // MatIconModule,
    // MatMenuModule,
    // MatToolbarModule,
    MaterialModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    MatDialogModule,
    FlexLayoutModule,
    CommonModule
  ],
  providers:[
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoleBasedAccessControlDirective } from './role-base-access/role-based-access-control.directive';
import { ControlErrorContainerDirective } from './validation/control-error-container.directive';
import { ControlErrorsDirective } from './validation/control-errors.directive';
import { ControlContainer } from '@angular/forms';
import { ControlErrorComponent } from './validation/control-error/control-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export function controlContainerFactory(controlContainer?: ControlContainer) {
  return controlContainer;
}

@NgModule({
  declarations: [RoleBasedAccessControlDirective, ControlErrorContainerDirective, ControlErrorsDirective, ControlErrorComponent],
  imports: [CommonModule, MatFormFieldModule, MatInputModule],
  providers: [
  ],
  exports: [ControlErrorContainerDirective, ControlErrorsDirective,RoleBasedAccessControlDirective]
})
export class CustomDirectiveModule { }

export const publicNavigation = {
  hash: 'CB69C8AA3E211AF9B01F0A2AFD983A86',
  navigationItems: [
    {
      active: false,
      icon: 'fas fa-home',
      id: 'public-website.dashboard',
      title: 'Home',
      type: 'menu-item',
      url: '/announcement',
      breadcrumb: [],
      children: [],
      order: 4,
    },
    {
      active: true,
      icon: 'fas fa-graduation-cap',
      id: 'public-website.education-schedule',
      lableAliasId:'ClinicalEducationSchedule',
      title: 'Clinical Education Schedule',
      type: 'menu-item',
      url: '/educationschedule',
      breadcrumb: [],
      children: [],
      order: 4,
    },
    {
      active: true,
      icon: 'fas fa-browser',
      id: 'public-website.resources-collapsable',
      title: 'Resources for Clinicians',
      lableAliasId:'ResourcesForClinicians',
      type: 'menu-collapsable',
      url: null,
      breadcrumb: [],
      order: 4,
      children: [
        {
          active: true,
          icon: 'fas fa-book-reader',
          id: 'public-website.resources',
          lableAliasId: 'public-website.resources',
          title: 'Program-wide',
          type: 'menu-item',
          url: '/resource/general',
          breadcrumb: [],
          children: [],
          order: 4,
        },
        {
          active: true,
          icon: 'fas fa-book-reader',
          id: 'public-website.resources-course',
          lableAliasId: 'public-website.resources-course',
          title: 'Course specific',
          type: 'menu-item',
          url: '/resource/course',
          breadcrumb: [],
          children: [],
          order: 4,
        },
      ],
    },
    {
      active: true,
      icon: 'fas fa-chalkboard-teacher',
      id: 'public-website.school-contact',
      title: 'Clinical Education Contacts',
      lableAliasId:'ClinicalEducationContacts',
      type: 'menu-item',
      url: '/schoolcontact',
      breadcrumb: [],
      children: [],
      order: 4,
    },
    {
      active: false,
      icon: 'fas fa-user',
      id: 'resource.header',
      title: '',
      type: 'nav-item',
      url: '',
      breadcrumb: [],
      children: [
        {
          active: false,
          icon: '',
          id: 'resource.header.general',
          lableAliasId: 'public-website.resources',
          title: 'Program Wide',
          type: 'item',
          url: '/resource/general',
          breadcrumb: [],
          children: [],
        },
        {
          active: false,
          icon: '',
          id: 'resource.header.courses',
          lableAliasId: 'public-website.resources-course',
          title: 'Course Specific',
          type: 'item',
          url: '/resource/course',
          breadcrumb: [],
          children: [],
        },
      ],
      order: 4,
    },
  ],
  ismodify: false,
};

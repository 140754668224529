import { UserFacade } from './../../store/facades/user.facade';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from './auth.service';
import { FusionConfigService } from '../../configuration/fusion-config.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  public tenantId: string = 'base';
  public oucode: string;
  public tenantName: string;
  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private readonly userfacade: UserFacade,
    private readonly config: FusionConfigService
  ) {
    this.routes = this.config.get('authGuardSettings');
    this.tenantName = this.route.snapshot.paramMap.get('tenantName');
    this.oucode = this.route.snapshot.paramMap.get('oucode');

    sessionStorage.setItem('tenantName', this.tenantName);
    sessionStorage.setItem('ouCode', this.oucode);
    sessionStorage.setItem('tenantId', this.tenantId);
  }

  routes: any;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url = route.url.toString();
    this.auth.redirectUrl = state.url;
    let result = false;

    this.userfacade.AuthState$.subscribe((authData) => {
      if (url === 'login' || url === 'signup') {
        if (authData != null && authData.accessToken != null) {
          if (
            sessionStorage.getItem('user.consent.consentedAt') === null ||
            sessionStorage.getItem('user.consent.consentedAt') === undefined
          ) {
            this.router.navigateByUrl('UserConsent');
          } else {
            this.router.navigateByUrl(this.routes.launchUrl);
          }
        }
        result = true;
      } else if (
        authData == null ||
        (authData !== null && authData.accessToken == null)
      ) {
        this.router.navigateByUrl(this.routes.loginUrl);
        result = false;
      }
      result = true;
    });
    return result;
  }
}

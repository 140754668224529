import { User, TokenModel } from '@exxat/fusion/models';
import { UserActionTypes, UserActions } from '../actions/user.actions';
import { EventsService, EventItem } from '../../event';
import { FoundationInjector } from '../../injector/foundation-injector';

export interface UserState {
  isAuthenticated: boolean;
  user: User | null;
  error: string | null;
}

const userSt: User = JSON.parse(sessionStorage.getItem('User'));
const authSt: TokenModel = JSON.parse(sessionStorage.getItem('Auth'));
export const initialUserState: UserState = {
  isAuthenticated: false,
  user: new User(),
  error: null,
};
if (userSt) {
  userSt.Token = authSt;
  initialUserState.user = userSt;
  initialUserState.isAuthenticated = true;
}

export function UserReducer(
  state = initialUserState,
  action: UserActions
): UserState {
  switch (action.type) {
    case UserActionTypes.LogInSuccess: {
      const eventService: EventsService = FoundationInjector.get(EventsService);
      const eventItem: EventItem = { payload: false };
      eventService.publish('LOGOUT', eventItem);
      let newUser: User = action.payload.user;
      newUser = {
        ...newUser,
        Token: action.payload.auth,
      };
      return {
        ...state,
        isAuthenticated: true,
        user: newUser,
        error: null,
      };
    }
    case UserActionTypes.LogInFailure: {
      return {
        ...state,
        error: action.payload?.error || 'login failure!!',
      };
    }
    case UserActionTypes.SignUpSuccess: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          Email: action.payload.Email,
          Token: action.payload.auth,
        },
        error: null,
      };
    }
    case UserActionTypes.SignUpFailure: {
      return {
        ...state,
        error: 'Signup error',
      };
    }
    case UserActionTypes.Logout:
    case UserActionTypes.AzureLogout: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: null,
      };
    }
    case UserActionTypes.LoadUser: {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        error: null,
      };
    }
    case UserActionTypes.UpdateUserSuccess: {
      let newUser = new User();
      newUser = { ...newUser, ...action.payload };
      newUser.Token = state.user.Token;
      newUser.UserRoles = state.user.UserRoles;
      return {
        ...state,
        isAuthenticated: true,
        user: newUser,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

export class OrgUnitInformation {

    id:string;
    timeZone: string;
    timezoneLocation:string;
    timezoneShortForm: string;
    oucode: string;
    description: string;
    isSelected: boolean;
}

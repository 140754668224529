import { Action } from '@ngrx/store';

export enum UserActionTypes {
  LogIn = '[Auth] Login',
  AzureLogin = '[Auth] Azure Login',
  LogInSuccess = '[Auth] Login Success',
  LogInFailure = '[Auth] Login Failure',
  SignUp = '[Auth] Signup',
  SignUpSuccess = '[Auth] Signup Success',
  SignUpFailure = '[Auth] Signup Failure',
  Logout = '[Auth] Logout',
  AzureLogout = '[Auth] Azure Logout',
  LoadUser = '[Auth] Load User',
  UpdateUser = '[Auth] Update User',
  UpdateUserSuccess = '[Auth] Update UserSuccess',
}

export class Login implements Action {
  readonly type = UserActionTypes.LogIn;
  constructor(public payload: any) {
  }
}

export class AzureLogin implements Action {
  readonly type = UserActionTypes.AzureLogin;
}

export class LoginSuccess implements Action {
  readonly type = UserActionTypes.LogInSuccess;
  constructor(public payload: any) {}
}

export class LoginFailure implements Action {
  readonly type = UserActionTypes.LogInFailure;
  constructor(public payload?: any) {}
}

export class SignUp implements Action {
  readonly type = UserActionTypes.SignUp;
  constructor(public payload: any) {}
}

export class SignUpSuccess implements Action {
  readonly type = UserActionTypes.SignUpSuccess;
  constructor(public payload: any) {}
}

export class SignUpFailure implements Action {
  readonly type = UserActionTypes.SignUpFailure;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = UserActionTypes.Logout;
}

export class AzureLogout implements Action {
  readonly type = UserActionTypes.AzureLogout;
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;
  constructor(public payload: any) {}
}
export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;
}
export class UpdateUserSuccess implements Action {

  readonly type = UserActionTypes.UpdateUserSuccess;
  constructor(public payload: any) {}
}

export type UserActions =
  | Login
  | AzureLogin
  | LoginSuccess
  | LoginFailure
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | Logout
  | AzureLogout
  | LoadUser
  | UpdateUser
  | UpdateUserSuccess;

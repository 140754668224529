import { PlatformLocation } from "@angular/common";
import { CompilerOptions, Injector, NgModuleRef, Type } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { XmlHttpRequestHelper } from "./XmlHttpRequestHelper";
import { AppConfigurationClient } from "@azure/app-configuration";

export function appInitializerFactory(
      injector: Injector,
      platformLocation: PlatformLocation) {
      return () => {
    
        return new Promise<boolean>((resolve, reject) => {
         const baseHref = getBaseHref(platformLocation);
         let appBaseUrl = getDocumentOrigin() + baseHref;
    
         AppPreBootstrap.run(appBaseUrl, () => {
         //initializeLocalForage();
    
         // Run all the code that need to be execute after bootstrap.
    
         resolve(true);
         }, resolve, reject);
        });
      };
    }


  
function getDocumentOrigin() {
    if (!document.location.origin) {
      return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }
  
    return document.location.origin;
  }
  
  function getBaseHref(platformLocation: PlatformLocation): string {
    let baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
      return baseUrl;
    }
  
    return '/';
  }
  
export class AppPreBootstrap {

    static run(appRootUrl: string, callback: () => void, resolve: any, reject: any): void {
        AppPreBootstrap.loadConfigJson(appRootUrl, () => {
           // AppPreBootstrap.getUserPermissions(callback);
           console.log("resolved for redirection");
           
           resolve(true);
        });
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static loadConfigJson(appRootUrl: string, callback: () => void) {
        let type = 'GET';
        let url = appRootUrl + 'assets/' + 'config.json';

        const customHeaders = null;
        XmlHttpRequestHelper.ajax(type, url, customHeaders, null, (result:any) => {
            console.log("Az App config.json", result);
            
            AppPreBootstrap.loadAzConfigs(result.azAppConfig);
            callback();
        });
    }

    private static async loadAzConfigs(connectionString: string){
        const client = new AppConfigurationClient(connectionString);
        var sampleKeys =client.listConfigurationSettings()
        for await (const setting of sampleKeys) {
            console.log(`Found key: ${setting.key}, label: ${setting.label}`);
          }
    }
}
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExxatTooltipComponent } from './components/exxat-tooltip/exxat-tooltip.component';
import { TooltipDirective } from './tooltip.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { TooltipOptions } from './tooltip-options.interface';
import { TooltipOptionsService } from './tooltip-options.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '../../shared.module';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [
    ExxatTooltipComponent,
    TooltipDirective,
    ClickOutsideDirective,
  ],
  imports: [CommonModule, FlexLayoutModule, FuseSharedModule, MaterialModule],
  exports: [TooltipDirective, ClickOutsideDirective],
})
export class ExxatTooltipModule {
  static forRoot(
    initOptions: TooltipOptions
  ): ModuleWithProviders<ExxatTooltipModule> {
    return {
      ngModule: ExxatTooltipModule,
      providers: [
        {
          provide: TooltipOptionsService,
          useValue: initOptions,
        },
      ],
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FusionNavigationComponent } from './navigation.component';
import { FusionNavItemComponent } from './menu-components/item/item.component';
import { FusionNavCollapsableComponent } from './menu-components/collapsable/collapsable.component';
import { FusionNavGroupComponent } from './menu-components/group/group.component';
import { FeatureMetaDataPipesModule } from '@exxat/fusion';

@NgModule({
    imports : [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatRippleModule,
        TranslateModule.forChild(),
        FeatureMetaDataPipesModule
    ],
    exports : [
        FusionNavigationComponent
    ],
    declarations : [
        FusionNavigationComponent,
        FusionNavItemComponent,
        FusionNavCollapsableComponent,
        FusionNavGroupComponent
    ]
})
export class FusionNavigationModule
{
}

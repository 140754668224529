import { Component, OnInit } from '@angular/core';
import { FusionConfigService, Logger } from '@exxat/fusion';

@Component({
  selector: 'ryzen-error-demo',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(private configService: FusionConfigService) {
    this.configService.uiSettings = {
      layout: {
        navbar: {
          hidden: true,
        },
        header: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit(): void {
    this.configService.uiSettings = {
      layout: {
        navbar: {
          hidden: true,
        },
        header: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    Logger.error(`PublicWebsite - PageNotFound - Invalid URL`);
  }
}

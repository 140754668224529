import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OrgUnitInformation } from '@exxat/fusion/models';

@Pipe({
  name: 'timezone',
})
export class TimezonePipe extends DatePipe implements PipeTransform {
  moment = require('moment-timezone');

  abbrs = {
    'Eastern Standard Time': 'EST',
    'Eastern Daylight Time': 'EDT',
    'Central Standard Time': 'CST',
    'Central Daylight Time': 'CDT',
    'Mountain Standard Time': 'MST',
    'Mountain Daylight Time': 'MDT',
    'Pacific Standard Time': 'PST',
    'Pacific Daylight Time': 'PDT',
    'Indian Standard Time': 'IST',
  };

  /**
   * Please Pass UTC Datetime stamp.
   */
  transform(date, format) {
    const currentOrgUnitInfo: OrgUnitInformation = JSON.parse(
      sessionStorage.getItem('orgUnitInformation')
    )?.find((x) => x.isSelected);

    if (currentOrgUnitInfo === (undefined || null)) {
      return super.transform(date, format);
    }

    const timezoneOffset = this.getTimeZoneOffset(date, currentOrgUnitInfo);
    if (timezoneOffset) {
      var SchoolTimeZoneDate = super.transform(date, format, timezoneOffset);
      return SchoolTimeZoneDate;
    }
    return date.toString();
  }

  getTimeZoneAbbr(): string {
    const currentOrgUnitInfo: OrgUnitInformation = JSON.parse(
      sessionStorage.getItem('orgUnitInformation')
    )?.find((x) => x.isSelected);
    return this.abbrs[currentOrgUnitInfo.timeZone];
  }

  getCurrentSchoolDate(requiredFormat: string): string {
    return this.transform(new Date(), requiredFormat);
  }

  getTimeZoneOffset(date, currentOrgUnitInfo): string {
    let timezoneOffset: string;
    if (currentOrgUnitInfo?.timezoneLocation !== (null || undefined)) {
      timezoneOffset = this.moment(date)
        .tz(currentOrgUnitInfo?.timezoneLocation)
        .format('Z');
    } else if (currentOrgUnitInfo?.timezoneShortForm !== (null || undefined)) {
      timezoneOffset = this.moment(date)
        .tz(currentOrgUnitInfo?.timezoneShortForm)
        .format('Z');
    } else {
      const abbreviation = this.abbrs[currentOrgUnitInfo?.timeZone];
      if (abbreviation !== (null || undefined)) {
        timezoneOffset = this.moment(date).tz(abbreviation).format('Z');
      }
    }
    return timezoneOffset;
  }
}

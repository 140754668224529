import { Injectable } from '@angular/core';
import { FusionConfigService, GET, Header, HttpService } from '@exxat/fusion';
import { VisibilityConfigurationModel } from '@exxat/publicwebsite/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VisibilityConfigurationApiClient extends HttpService {
  constructor(protected fusionConfigService: FusionConfigService) {
    super();
  }

  getBaseUrl() {
    return this.fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<VisibilityConfigurationModel>('/VisibilityConfiguration')
  public getVisibilityConfiguration(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<VisibilityConfigurationModel> {
    return null;
  }
}

import { NgModule } from '@angular/core';
import { ExportExcelService } from './export.excel.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    ExportExcelService
  ],
  bootstrap: []
})
export class ExportExcelModule { }

import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from './fuse-if-on-dom/fuse-if-on-dom.directive';
import { FuseInnerScrollDirective } from './fuse-inner-scroll/fuse-inner-scroll.directive';
import { FusePerfectScrollbarDirective } from './fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from './fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { ExxatDrawerActionDirective } from './exxat-sidebar-action/exxat-drawer-action.directive';
import { NoWhitespaceDirective } from './no-whitespace/no-whitespace.directive';
import { ExxatManifoldPanelActionDirective } from './exxat-sidebar-action/exxat-manifold-panel-action.directive';
import { NavMenuListDirective } from './high-light-nav-menu/nav-menu-list.directive';
import { HighLightNavMenuContentDirective } from './high-light-nav-menu/high-light-nav-menu-cotent.directive';
import { FocusDirective } from './accessibility/focus.directive';
import { ResizableDirective } from './resizable/resizable.directive';
import { FontColorDirective } from './font-color/font-color.directive';

@NgModule({
  declarations: [
    FuseIfOnDomDirective,
    FuseInnerScrollDirective,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePerfectScrollbarDirective,
    ExxatDrawerActionDirective,
    ExxatManifoldPanelActionDirective,
    NoWhitespaceDirective,
    FocusDirective,
    NavMenuListDirective,
    HighLightNavMenuContentDirective,
    ResizableDirective,
    FontColorDirective
  ],
  imports: [],
  exports: [
    FuseIfOnDomDirective,
    FuseInnerScrollDirective,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePerfectScrollbarDirective,
    ExxatDrawerActionDirective,
    ExxatManifoldPanelActionDirective,
    NoWhitespaceDirective,
    FocusDirective,
    NavMenuListDirective,
    HighLightNavMenuContentDirective,
    ResizableDirective,
    FontColorDirective
  ]
})
export class FuseDirectivesModule {
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent, ConfirmDialogModel } from './confirm-dialog.component';
import { FusionFormComponent, FusionFormAdapter } from '@exxat/fusion/components';
import { take, map } from 'rxjs/operators';

@Injectable()
export class ConfirmDialogService {
  dialogRef;
  constructor(
    private dialog: MatDialog
  ) {}

  open(confirmMessage: string, confirmActions: any) {
    this.dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      width: "500px",
      data: new ConfirmDialogModel(confirmMessage, confirmActions),
      disableClose: true
    });
  }

  confirmDialogAction() {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
  ));
  }

  primaryAction() { }
  secondaryAction() { }
}

import { NgModule } from '@angular/core';
import { NotificationService } from './notification.service';

@NgModule({
  imports: [],
  providers: [
    NotificationService,
  ],
  declarations: [],
  exports: [ ]
})
export class UtilityModule { }

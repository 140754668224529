import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { FusionConfigService } from '../../configuration/index';
import { FoundationInjector } from '../../injector/foundation-injector';

@Injectable()
export class LoggingService {
  private static appInsights;
  private static router;
  private static excludedTenants = [];

  static setApplicationInsights() {
    LoggingService.router = FoundationInjector.get(Router);
    var config = FoundationInjector.get(FusionConfigService);
    LoggingService.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.appSettings.instrumentationKey,
        enableAutoRouteTracking: true, // send pageViews on route change
        maxAjaxCallsPerView: -1, // Send all the events (default is 500 per page)
      },
    });

    LoggingService.appInsights.loadAppInsights();

    // ITelemetryInitializer - to filer requests, traces | add custom properties to telemetry data
    LoggingService.appInsights.addTelemetryInitializer((envelope) => {
      var telemetryItem = envelope?.data?.baseData;

      const storage = sessionStorage.getItem('User')
        ? sessionStorage
        : localStorage;
      if (storage.getItem('User')) {
        const user = JSON.parse(storage.getItem('User'));
        LoggingService.appInsights.setAuthenticatedUserContext(user?.UserName);
      }

      // if (envelope.data.someField === 'tobefilteredout') {
      //   return false;
      // }
      // return true;
    });

    LoggingService.excludedTenants =
      config?.appSettings?.clickTrackingSettings?.excludedTenants;
  }

  static logPageView(name?: string, uri?: string) {
    LoggingService.appInsights.trackPageView({ name, uri });
  }

  static setUserName(userName: string) {
    LoggingService.appInsights.setAuthenticatedUserContext(userName);
  }

  static clearUserName() {
    LoggingService.appInsights.clearAuthenticatedUserContext();
  }

  static logEvent(name: string, properties?: any, measurements?: any) {
    LoggingService.appInsights.trackEvent({
      name: name,
      properties: properties,
      measurements: measurements,
    });
  }

  static logException(
    exception: Error,
    handledAt?: string,
    properties?: any,
    measurements?: any
  ) {
    let error: any = {};
    error.exception = exception;
    LoggingService.appInsights.trackException(
      error,
      handledAt,
      properties,
      measurements
    );
  }

  static logTrace(message: string, properties?: any, severityLevel?: any) {
    LoggingService.appInsights.trackTrace({
      message: message,
      properties: properties,
      severityLevel: severityLevel,
    });
  }

  static logCustomEvents(event) {
    let tenantName, ouCode;
    if (sessionStorage.getItem('ouCode')) {
      tenantName = sessionStorage.getItem('tenantName');
      ouCode = sessionStorage.getItem('ouCode');
    }
    if (ouCode && !LoggingService.excludedTenants?.includes(tenantName)) {
      const route = LoggingService.router.url;
      const title = document.title;
      let clickedText;
      if (event?.target?.innerText) {
        //Button Clicked
        clickedText = event?.target?.innerText;
      } else if (event?.target?.nextElementSibling?.innerText) {
        //Checkbox Clicked
        clickedText = event?.target?.nextElementSibling?.innerText;
      } else if (event?.target?.parentElement?.nextElementSibling?.innerText) {
        clickedText =
          event?.target?.parentElement?.nextElementSibling?.innerText; // RadioButton Clicked
      }
      if (clickedText && clickedText.trim() != '')
        LoggingService.logEvent(title, {
          TenantName: tenantName,
          OuCode: ouCode,
          Route: route,
          ClickedText: clickedText,
        });
    }
  }
}

import { NgModule } from '@angular/core';
import { ClinicalEducationScheduleSandBox } from './clinical-education-schedule/clinical-education-schedule.sandbox';
import { AnnouncementApiClient } from './announcement/announcement.ApiClient';
import { AnnouncementSandBox } from './announcement/announcement.sandbox';
import { MetaApiClient } from './meta.ApiClient';
import { MetaSandbox } from './meta.sandbox';
import { OuNameService } from './ouName.service';
import { ResourcesApiClient } from './resources/resources-api-client';
import { ResourcesSandBox } from './resources/resources.sandbox';
import { SchoolContactApiClient } from './school-contact/school-contact-api-client';
import { WelcomeMsgApiClient } from './WelcomeMsg/welcomeMsg-api-client';
import { WelcomeMsgSandbox } from './WelcomeMsg/welcomeMsg.sandbox';
import { ClinicalEducationScheduleApiClient } from './clinical-education-schedule/clinical-education-schedule.ApiClient';
import { VisibilityConfigurationApiClient } from './visibility-configuration/visibility-configuration.api-client';
import { UserMsgApiClient } from './user-msg/user-msg-api.client';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MetaApiClient,
    MetaSandbox,
    AnnouncementApiClient,
    AnnouncementSandBox,
    SchoolContactApiClient,
    ResourcesSandBox,
    ResourcesApiClient,
    WelcomeMsgSandbox,
    WelcomeMsgApiClient,
    OuNameService,
    ClinicalEducationScheduleSandBox,
    ClinicalEducationScheduleApiClient,
    VisibilityConfigurationApiClient,
    UserMsgApiClient
  ]
})
export class PublicwebSiteServicesModule {}

import { Component } from '@angular/core';
import { UserConsentSandbox } from '../../core/services/user-consent/user-consent.sandbox';
import { ActivatedRoute, Router } from '@angular/router';
import { FusionConfigService } from '../../core/configuration/fusion-config.service';

@Component({
  selector: 'user-consent',
  templateUrl: './user-consent.component.html',
  styleUrls: ['./user-consent.component.scss'],
})
export class UserConsentComponent {
  consentKey = 'user.consent.consentedAt';
  private readonly launchUrl: string;
  pageContent = 'Please wait...';
  consentText = '';
  showContent = false;

  constructor(
    private readonly userConsentSandbox: UserConsentSandbox,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private configService: FusionConfigService
  ) {
    this.launchUrl = this.configService.get('authGuardSettings').launchUrl;
    this.configService.uiSettings = {
      layout: {
        navbar: {
          hidden: true,
        },
        header: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit() {
    const consentedAt = sessionStorage.getItem('user.consent.consentedAt');
    if (consentedAt) this.router.navigateByUrl(this.launchUrl);
    else {
      this.userConsentSandbox.getConsentAgreeement().subscribe((data) => {
        this.consentText = data.value;
        this.pageContent = '';
        this.showContent = true;
      });
    }
    this.configService.uiSettings = {
      layout: {
        navbar: {
          hidden: true,
        },
        header: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  onAccepted(accepted: boolean) {
    if (accepted) {
      this.userConsentSandbox.createConsent().subscribe((resp) => {
        if (resp != null) {
          sessionStorage.setItem(this.consentKey, resp.consentedAt);
        }
        this.activatedRoute.queryParams.subscribe((params) => {
          const targetUrl = params.targetUrl;
          const targetUrlendpoint = `?targetUrl=${encodeURIComponent(
            targetUrl
          )}`;
          this.router.navigateByUrl(
            `${this.launchUrl}${targetUrl ? targetUrlendpoint : ''}`
          );
        });
      });
    }
  }
}

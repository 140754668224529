import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SplashScreenService } from '@exxat/ux';
import { FusionNavigationService } from '@exxat/fusion/services';

import { productNavigation } from '@exxat/ux';
import { FusionConfigService, LoggingService } from '@exxat/fusion/core';
import { ProductNavigation } from '@exxat/fusion/models';
import { FusionConfig } from '../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: ProductNavigation[];
  click$: Observable<any>;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private platform: Platform,
    private navigationService: FusionNavigationService,
    private splashScreenService: SplashScreenService,
    private _fusionConfigService: FusionConfigService
  ) // private appInsightService: ApplicationInsightsService,
  // private sanitizer: DomSanitizer
  {
    // Get default navigation
    this.navigation = productNavigation;

    // Register the navigation to the service
    // this.fuseNavigationService.register(productNavigation[0].productName, productNavigation[0].menu);
    // Set the main navigation as our current navigation
    // this.fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    if (
      _fusionConfigService?.appSettings?.clickTrackingSettings
        ?.clickTrackingEnabled
    ) {
      this.click$ = fromEvent(document, 'click');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    console.log("App component ngonInit");
    
    // Subscribe to config changes
    this._fusionConfigService.uiSettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    this.click$?.subscribe((event) => {
      LoggingService.logCustomEvents(event);
    });
  }
  private setAppInsights() {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

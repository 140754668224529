export class ComponentMap {
  componentMap: Map<string, any>;

  constructor() {
    this.componentMap = new Map(null);
  }

  get(componentKey: string) {
    return this.componentMap.get(componentKey);
  }

  add(componentKey, componentType: any) {
    return this.componentMap.set(componentKey, componentType);
  }
}

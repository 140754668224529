import { Injectable } from '@angular/core';
import {
  Body,
  FusionConfigService,
  GET,
  Header,
  HttpService,
  Path,
  POST,
} from '@exxat/fusion';
import {
  Category,
  CategoryResources,
  Course,
  CourseDocument,
} from '@exxat/publicwebsite/models';
import { Observable } from 'rxjs';

@Injectable()
export class ResourcesApiClient extends HttpService {
  constructor(protected fusionConfigService: FusionConfigService) {
    super();
  }

  getBaseUrl() {
    return this.fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<Category[]>('/resourcecategory')
  public getResources(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<Category[]> {
    return null;
  }

  @GET<Course[]>('/course')
  public getCourses(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<Course[]> {
    return null;
  }

  @POST<string[]>('/public/File/CheckFileExist', null)
  public checkFileExistForFileCollectionKey(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string,
    @Body fileCollectionKeys: string[]
  ): Observable<string[]> {
    return null;
  }

  @GET<CourseDocument[]>('/course/{courseId}')
  public getCourseCategories(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string,
    @Path('courseId') courseId: any
  ): Observable<CourseDocument[]> {
    return null;
  }

  @GET<CategoryResources[]>('/course/{courseId}/{categoryId}')
  public getCategoryResources(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string,
    @Path('courseId') courseId: any,
    @Path('categoryId') categoryId: any
  ): Observable<CategoryResources[]> {
    return null;
  }
}

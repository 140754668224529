import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Announcement } from '@exxat/publicwebsite/models';

import {
  GET,
  FusionConfigService,
  HttpService,
  Header,
  Path,
} from '@exxat/fusion/core';

type GUID = string & { isGuid: true };

@Injectable()
export class AnnouncementApiClient extends HttpService {
  constructor(protected _fusionConfigService: FusionConfigService) {
    super();
  }

  getBaseUrl() {
    return this._fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<Announcement>('/announcement')
  public getAnnouncement(
    @Header('TenantId') tenantId: string,
    @Header('Oucodes') oucodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<Announcement[]> {
    return null;
  }
  @GET<Announcement>('/announcement/{id}')
  public getAnnouncementDetail(
    @Header('TenantId') tenantId: string,
    @Header('Oucodes') oucodes: string,
    @Header('TenantName') tenantName: string,
    @Path('id') id: string
  ): Observable<Announcement> {
    return null;
  }
}

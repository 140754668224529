import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBarWithCloseComponent } from '../components/exxat-snack-bar/components';

@Injectable({
  providedIn: 'any'
})
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) { }

  openSnackBar(message: string, action?: string,
    duration?: number, verticalPosition?: MatSnackBarVerticalPosition, horizontalPosition?: MatSnackBarHorizontalPosition,
    panelClass?: string
  ) {
    this.zone.run(() => {
      this.snackBar.open(message, !!action ? action : '',
        {
          duration: !!duration ? duration : 3000,
          panelClass: !!panelClass ? [panelClass] : ['exxat-snack-bar'],
          verticalPosition: !!verticalPosition ? verticalPosition : 'bottom',
          horizontalPosition: !!horizontalPosition ? horizontalPosition : 'center'
        });
    });
  }

  openSnackBarWithClose(message: string, duration: number = 0) {
    const config: MatSnackBarConfig = {
      data: message,
      duration: duration, 
      panelClass: ['snackbar-success']
    }
    this.snackBar.openFromComponent(SnackBarWithCloseComponent, config);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GET, Header, HttpService} from '@exxat/fusion';

@Injectable({ providedIn: 'any' })
export class LookupAPIClientService extends HttpService {
  constructor() {
    super();
  }

  getBaseUrl() {
    return this.configService.getservice('lookup.service').endpoint;    
  }

  @GET<any>('/Labels')
  public getSettingDictionary(
    @Header('tenantId') tenantId: string,
    @Header('oucodes') oucodes: string,
    @Header('tenantName') tenantName: string
  ): Observable<any> {
    return null;
  }
}

export class OucodeHelper {
  static getOucodeList(oucodeArr, Oucodes, status) {
    if (Array.isArray(Oucodes)) {
      Oucodes.forEach((element, index) => {
        if (status) {
          // if not base tenant
          if (element.isSelected)
            // then take only selected
            oucodeArr.push(element.Oucode);
        } else {
          oucodeArr.push(element.Oucode);
        }
        if (element.hasOwnProperty('Children')) {
          this.getOucodeList(oucodeArr, element.Children, status);
        }
      });
    }
    return oucodeArr;
  }

  static getOuCodeFlatList(oucodeTree) {
    return this.convertOucodesFlatList([], oucodeTree, '', 0, '');
  }

  static convertOucodesFlatList(oucodeArr, Oucodes, OucodeStr, level, parent) {
    if (Array.isArray(Oucodes)) {
      level = level + 1;
      OucodeStr = this.setOucodeStr(level, OucodeStr, parent);

      Oucodes.forEach((element, index) => {
        const node = this.setNodeValue(level, [], OucodeStr, element);
        if (
          element.hasOwnProperty('Children') &&
          element['Children'].length > 0
        ) {
          oucodeArr.push(node);
          parent = this.getParentName(level, element, parent);
          this.convertOucodesFlatList(
            oucodeArr,
            element.Children,
            OucodeStr,
            level,
            parent
          );
        } else {
          oucodeArr.push(node);
        }
      });
    }
    return oucodeArr;
  }

  private static setOucodeStr(level: any, OucodeStr: any, parent: any) {
    if (level > 1) {
      OucodeStr = (OucodeStr === '' ? '' : `${OucodeStr}-`) + parent;
    }
    return OucodeStr;
  }

  private static getParentName(level, element, parent) {
    if (level > 1) {
      return element.Name;
    }
    return parent;
  }

  private static setNodeValue(level, node, OucodeStr, element) {
    node['key'] = element.Oucode;
    if (level > 2) {
      node['value'] = (OucodeStr === '' ? '' : `${OucodeStr}-`) + element.Name;
    } else {
      node['value'] = element.Name;
    }
    return node;
  }

  static getProgramList(_oucodeWithNames,Oucodes) {
    if(Array.isArray(Oucodes)) {
      Oucodes.forEach((element,index) => {
        const oucodeWithName = [];
        oucodeWithName['key']=element.Oucode;
        oucodeWithName['value']= element.fullName.replace('.',' - ');        
        if(element.hasOwnProperty('Children') && element['Children'].length>0) {
          this.getProgramList(_oucodeWithNames,element.Children);
        }
        else {
          _oucodeWithNames.push(oucodeWithName);
        }
      });
    }
    return _oucodeWithNames;
  }
}

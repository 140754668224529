import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OuCode,
  TenantWithOuCodes,
  TokenModel,
  User
} from '@exxat/fusion/models';
import { HttpClient } from '@angular/common/http';
import {
  FusionConfigService,
  UserFacade,
  AuthService
} from '@exxat/fusion/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'auto',
  template: `
    <ng-template #validationDialog>
      <h2 matDialogTitle>Link Expired</h2>
      <div *ngIf="!isLinkExpired">
        <p>Oops, the link you followed has expired. This may have happened due to some changes in the workflow</p>
      </div>
      <div *ngIf="isLinkExpired">
        <p>This link has been deactivated due to link expiration date arrival</p>
      </div>
      <div align="end">
        <button mat-button matDialogClose color="primary">Dismiss</button>
      </div>
    </ng-template>
  `
})
export class DelegatorComponent implements OnInit {
  key: string;
  tokenModel: TokenModel = new TokenModel();
  tenantId: string;
  targetUrl: string;
  tenantWithOuCodes: Array<TenantWithOuCodes> = new Array();
  OuCodes: Array<OuCode> = new Array();
  @ViewChild('validationDialog', { static: true })
  validationDialog: TemplateRef<any>;
  isLinkExpired: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly configService: FusionConfigService,
    private readonly authService: AuthService,
    private readonly userFacade: UserFacade,
    private readonly dialog: MatDialog
  ) {
    this.configService.uiSettings = {
      layout: {
        navbar: {
          hidden: true
        },
        header: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.key = queryParams.get('key');
    });

    this.httpClient
      .get<any>(
        `${
          this.configService.appSettings.auth.endpoint
        }/Gateway/Delegator?key=${this.key}`
      )
      .toPromise()
      .then(x => {
        this.tokenModel.accessToken = x.item1;
        this.tokenModel.refreshToken = x.item2;
        this.tokenModel.expiration = x.item3;
        this.targetUrl = x.item4;
        const tokenData = this.authService.parseJwtToken(
          this.tokenModel.accessToken
        );
        if (tokenData !== undefined) {
          const userData: User = JSON.parse(
            tokenData[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'
            ]
          );
          this.userFacade.LoginSuccess({
            auth: this.tokenModel,
            user: userData,
            targetUrl: this.targetUrl
          });
        }
      })
      .catch(x => {
        debugger;
        if(x.error?.statusCode == 410)
          this.isLinkExpired = true;
        if (x.status === 401 && x.statusText === 'Unauthorized')
          this.dialog.open(this.validationDialog, {
            width: '50vw'
          });
      });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FuseSharedModule } from '../../../shared.module';

import { DrawerComponent } from './drawer.component';
import { DrawerService } from './drawer.service';
import { DrawerContentDirective } from './drawer-content.directive';
import { FuseDirectivesModule } from '../../../directives';
import { FuseProgressBarModule } from '../../../components/progress-bar/progress-bar.module';
import { FuseConfirmDialogModule } from '../../../components/confirm-dialog/confirm-dialog.module';
import { UXStoreModule } from '../../../store/ux-store.module';
import { MaterialModule } from '../../../material.module';

@NgModule({
  declarations: [
    DrawerComponent,
    DrawerContentDirective
  ],
  providers: [
    DrawerService
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FuseDirectivesModule,
    FuseProgressBarModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    UXStoreModule
  ],
  exports: [
    DrawerComponent
  ],
})
export class DrawerModule {
}

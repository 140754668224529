import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AuthSandbox } from '@exxat/fusion/core';

@Directive({
  selector: '[featureCode]',
})
export class RoleBasedAccessControlDirective implements OnInit {
  @Input('featureCode') rolecode: any;
  @Input() params;

  public myAccessRights: Array<Object> = [
    { id: 'READ', value: 'GET' },
    { id: 'WRITE', value: 'POST' },
    { id: 'UPDATE', value: 'PUT' },
    { id: 'DELETE', value: 'DELETE' },
  ];

  constructor(
    private readonly elm: ElementRef,
    private readonly renderer: Renderer2,
    private readonly authSandbax: AuthSandbox
  ) {}
  ngOnInit() {
    let roleMeta = JSON.parse(sessionStorage.getItem('Meta.Role'));

    const rolecode = this.rolecode.split(':');
    if (!roleMeta && rolecode.length > 1) {
      const HashCode = !roleMeta ? null : roleMeta.hash;
      this.authSandbax
        .role(HashCode)
        .pipe()
        .subscribe((response) => {
          if (response['isModified'] === true)
            sessionStorage.setItem('Meta.Role', JSON.stringify(response));
          roleMeta = response;
        })
        .unsubscribe();
    }

    if (this.rolecode && roleMeta != null) {
      const roleArr = this.getRoleArray(roleMeta);
      this.checkRole(roleArr, this.rolecode);
    }
  }

  private getRoleArray(roleMeta: any) {
    let roleArr = [];
    if (roleMeta.data.length > 1) {
      roleMeta.data.forEach((element) => {
        roleArr.push(element['access']);
      });
      roleArr = [].concat(...roleArr);
    } else {
      roleArr = roleMeta.data[0]['access'];
    }
    return roleArr;
  }

  checkRole(data, rolecode) {
    rolecode = rolecode.split(':');

    const microserviceCode = data.filter(function (res) {
      return res.microserviceCode === rolecode[0];
    });

    if (microserviceCode.length > 0) {
      const featureCode = microserviceCode[0]['features'].filter(function (
        res
      ) {
        return res.featureCode === rolecode[1];
      });
      let flag = 0;
      if (featureCode.length > 0) {
        flag = this.hasAccess(featureCode, flag);
      }
    } else {
      if (rolecode.length === 1) {
        this.renderer.setStyle(this.elm.nativeElement, 'display', 'none');
      }
    }
  }

  private hasAccess(featureCode: any, flag: number) {
    for (const can of this.params['CAN']) {
      const val = this.myAccessRights.filter((pilot) => pilot['id'] === can);
      if (featureCode[0]['permissions'].includes(val[0]['value'])) {
        flag = 1;
      }
    }
    if (flag === 0) {
      this.renderer.setStyle(this.elm.nativeElement, 'display', 'none');
    }
    return flag;
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'exxat-breadcrumb',
  templateUrl: './exxat-breadcrumb.component.html',
  styleUrls: ['./exxat-breadcrumb.component.scss']
})
export class ExxatBreadcrumbComponent implements OnInit {

  @Input() dataSource;

  constructor() {

  }

  ngOnInit() {
    this.dataSource = this.sortArray(this.dataSource);
  }

  sortArray(array: Array<any>) {
    return array.sort((a, b) => {
      return a.order - b.order;
    });
  }
}

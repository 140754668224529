// user-resolver.service.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MetaSandbox } from '@exxat/publicwebsite/services';
import {  Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TenantNameResolver implements Resolve<any> {

    oucode: string;
    constructor(public _metaSandbox: MetaSandbox) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const paramTenantId = route.queryParamMap.get('tenantId')?.trim();
        if (paramTenantId !== null && paramTenantId !== undefined)
            return this._metaSandbox.getTenantInformation(paramTenantId);
        else
            return of('API call not needed');
    }
}

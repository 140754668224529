import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExxatAvatarComponent } from './exxat-avatar.component';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [ExxatAvatarComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [ExxatAvatarComponent]
})
export class ExxatAvatarModule { }

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {ClinicalEducationSchedule, ClinicalEducationSchedulePT} from '@exxat/publicwebsite/models'
import {ClinicalEducationScheduleApiClient } from '@exxat/publicwebsite/services';

@Injectable()
export class ClinicalEducationScheduleSandBox{
    constructor(private _clinicalEducationScheduleApiClient : ClinicalEducationScheduleApiClient){
        
    }

    public getRotationDetails(tenantId,oucode, tenantName):Observable<ClinicalEducationSchedule[]>{
        return this._clinicalEducationScheduleApiClient.getRotationDetails(tenantId,oucode, tenantName);
    }

    public getRotationDetailsPT(tenantId,oucode, tenantName):Observable<ClinicalEducationSchedulePT[]>{
        return this._clinicalEducationScheduleApiClient.getRotationDetailsPT(tenantId,oucode, tenantName);
    }
}
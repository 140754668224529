import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
// Ryzen Imports
import { FuseDirectivesModule, FuseSidebarModule, FuseSharedModule, LayoutModule, FuseProgressBarModule, MaterialModule } from "@exxat/ux";
import {appInitializerFactory} from '@exxat/fusion/core'

// App Imports

import {BaseCoreModule, FusionModule} from '@exxat/fusion';


import { AppComponent } from './app.component';
import { FusionConfig } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { PlatformLocation } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FuseSidebarModule,
    MaterialModule,
    FuseProgressBarModule,
    FuseSharedModule,
    BaseCoreModule,
    FusionModule,
    LayoutModule,
    FuseDirectivesModule,
    AppRoutingModule,
    HammerModule,
    SimpleNotificationsModule.forRoot(),    
  ],
  bootstrap: [AppComponent],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [Injector, PlatformLocation],
    //   multi: true,
    // },
  ],
})
export class AppModule {
  constructor() { }
}

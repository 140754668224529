import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { MaterialModule } from '../material.module';
import { SnackbarService } from '../services/snackbar.service';
import { reducers } from "./reducers/uistate.reducers";
import { effects } from './effects/uistate.effects';
import { RouterStateFacade } from './facade/router.facade';
import { CustomSerializer } from './reducers/router.reducers';
import { PageFacade } from './facade/page.facade';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    StoreModule.forFeature('ui', reducers),
    EffectsModule.forFeature(effects),
    StoreRouterConnectingModule.forRoot({ stateKey: "router", serializer: CustomSerializer }),
  ],
  providers: [RouterStateFacade, PageFacade, SnackbarService]
})
export class UXStoreModule { }

export class MethodType {
    public static readonly GET = 'GET';
    public static readonly POST = 'POST';
    public static readonly PUT = 'PUT';
    public static readonly DELETE = 'DELETE';
}

export class HttpConsants {
    public static readonly Base = 'Base';
    public static readonly BaseOucode = '1000';
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    
    public windowHeight: BehaviorSubject<number>;
    public currentHeaderHeight: BehaviorSubject<number>;
    public scrollContentheight: number;
    defaultAddedHeight = 112;
    /**
     *
     */
    constructor() {
        this.currentHeaderHeight = new BehaviorSubject<number>(100);
        this.windowHeight = new BehaviorSubject<number>(window.innerHeight);
    }

    getCurrentHeaderHeight() {
        return this.currentHeaderHeight.value;
    }

    setWindowHeight(height) {
        this.windowHeight = new BehaviorSubject<number>(height);
        this.scrollContentheight = this.currentHeaderHeight.value + this.defaultAddedHeight;
        this.scrollContentheight = this.windowHeight.value - this.scrollContentheight;
    }
    setCurrentHeaderHeight(height: any) {
        this.currentHeaderHeight = new BehaviorSubject<number>(height);
        this.scrollContentheight = this.currentHeaderHeight.value + this.defaultAddedHeight;
        this.scrollContentheight = this.windowHeight.value - this.scrollContentheight;
    }


}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FusionConfigService, HttpService, Header, POST, Body } from '@exxat/fusion/core';
import { UserMsgModel } from '@exxat/publicwebsite/models'

@Injectable()
export class UserMsgApiClient extends HttpService {
  constructor(protected _fusionConfigService : FusionConfigService) {
    super();
  }

  protected getBaseUrl(): string {
    return this._fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @POST<UserMsgModel>('/UserMsg', null)
  public addUserMsg(
    @Header('TenantId') tenantId: string,
    @Header('Oucodes') oucodes: string,
    @Header('TenantName') tenantName: string,
    @Body any): Observable<UserMsgModel> {
    return null;
  }

}


import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromUserActions from '../actions/user.actions';

import { AppState ,getAuthState,getUserState} from './../selectors/app.states';
import { UserState } from '../reducers/user.reducers';

import { TokenModel } from '@exxat/fusion/models';


@Injectable()
export class UserFacade {

    public UserState$: Observable<UserState> =  this.appState$.pipe(select(getUserState));
    public AuthState$: Observable<TokenModel> =  this.appState$.pipe(select(getAuthState));

    constructor(private readonly appState$: Store<AppState>) {
       }


    Login(payload: any) {
        this.appState$.dispatch(new fromUserActions.Login(payload));
    }

    AzureLogin(){
        this.appState$.dispatch(new fromUserActions.AzureLogin());
    }

    LoginSuccess(payload: any) {
        this.appState$.dispatch(new fromUserActions.LoginSuccess(payload));
    }

    LoginFailure(payload: any) {
        this.appState$.dispatch(new fromUserActions.LoginFailure(payload));
    }

    SignUp(payload: any) {
        this.appState$.dispatch(new fromUserActions.SignUp(payload));
    }

    SignUpSuccess(payload: any) {
        this.appState$.dispatch(new fromUserActions.SignUpSuccess(payload));
    }

    updateUser(){
        this.appState$.dispatch(new fromUserActions.UpdateUser());
    }
    updateUserSuccess(payload: any){
        this.appState$.dispatch(new fromUserActions.UpdateUserSuccess(payload));
    }
    SignUpFailure(payload: any) {
        this.appState$.dispatch(new fromUserActions.SignUpFailure(payload));
    }

    LoadUser(payload: any) {
        this.appState$.dispatch(new fromUserActions.LoadUser(payload));
    }

    LogOut() {
        this.appState$.dispatch(new fromUserActions.Logout());
    }

    AzureLogout(){
        this.appState$.dispatch(new fromUserActions.AzureLogout());
    }
}

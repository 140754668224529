import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarWithCloseComponent } from './components/snack-bar-with-close/snack-bar-with-close.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [SnackBarWithCloseComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSnackBarModule
  ],
  providers: [SnackbarService],
  exports: [SnackBarWithCloseComponent]
})
export class ExxatSnackBarModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeywordDictionaryPipe } from './keywords/keyword-dictionary.pipe';
import { LookUpsPipe } from './lookups/lookups.pipe';
import { LabelDictionaryPipe } from './lookups/setting-dictionary.pipe';
import { TimezonePipe } from './timezone/timezone.pipe';

@NgModule({
    declarations:[LookUpsPipe,KeywordDictionaryPipe, TimezonePipe, LabelDictionaryPipe],
    imports:[CommonModule],
    providers:[],
    exports:[LookUpsPipe,KeywordDictionaryPipe, TimezonePipe, LabelDictionaryPipe]
  })
export class FeatureMetaDataPipesModule{}

import { Component, OnInit, Input } from '@angular/core';
import { FusionNavigationService } from '@exxat/fusion/services';
import lodash from 'lodash';

import { ActivatedRoute } from '@angular/router';
import { GlobalVariable } from '@exxat/fusion';

@Component({
  selector: 'exxat-tabnavbar',
  templateUrl: './tabnavbar.component.html',
})
export class TabNavbarComponent implements OnInit {
  @Input() navigationId;
  navigations: any[];
  programResourceLabel = 'Program Wide';
  courseResourceLabel = 'Course Specific';

  constructor(
    private _fusionNavigationService: FusionNavigationService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.programResourceLabel = GlobalVariable.settingDictionary.get('public-website.resources')?.value !== undefined ? GlobalVariable.settingDictionary.get('public-website.resources')?.value: this.programResourceLabel;
    this.courseResourceLabel = GlobalVariable.settingDictionary.get('public-website.resources-course')?.value !== undefined ? GlobalVariable.settingDictionary.get('public-website.resources-course')?.value: this.courseResourceLabel;
  }

  ngOnInit() {
    this.navigations =
      this._fusionNavigationService.getNavigationItemForPublicWebsite(
        this.navigationId
      )['children'];

      this.navigations.forEach(n=>{
        if(n.id == 'resource.header.general'){
          n.title = this.programResourceLabel;
        } else if(n.id == 'resource.header.courses') {
          n.title = this.courseResourceLabel;
        }
      })

    this.activateNavigation(
      this.navigations.find(
        (x) =>
          x.url.split('/')[x.url.split('/').length - 1] ===
          this._activatedRoute.snapshot['_routerState'].url.split('/')[
            this._activatedRoute.snapshot['_routerState'].url.split('/')
              .length - 1
          ]
      )
    );
    // this._router.currentUrlTree.root.children.primary.segments[this._router.currentUrlTree.root.children.primary.segments.length - 1].path;
  }

  activateNavigation(link) {
    if (link != undefined) {
      const navigationItem = lodash.cloneDeep(
        this.navigations.find((x) => x.id === link.id)
      );
      navigationItem.active = true;
    }
  }

  createRouteURL(link): string {
    let ouCode = sessionStorage.getItem('ouCode');
    let tenantName = sessionStorage.getItem('tenantName');
    return `/${ouCode}/${tenantName}${link}`;
  }
}

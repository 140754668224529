import { Injectable, ComponentFactoryResolver, Renderer2, RendererFactory2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FusionFormComponent, FusionFormAdapter } from '@exxat/fusion/components';

import { LayoutService } from '../../vertical/layout-1/layout-1.service';
import { ManifoldPanelComponent } from './manifold-panel.component';
import { FuseSidebarService } from '../../../components/sidebar/sidebar.service';
import { ManifoldPanel } from './manifold-panel';
import { FuseProgressBarService } from '../../../components/progress-bar/progress-bar.service';

@Injectable()
export class ManifoldPanelService {
  componentRef: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _layoutService: LayoutService,
    private _fuseSidebarService: FuseSidebarService,
    private _rendererFactory: RendererFactory2,
    private _fuseProgressBarService: FuseProgressBarService
  ) {
    this._unsubscribeAll = new Subject();
  }

  async openPanel(componentType: any) {
    const panelExistsInRegistry = this._fuseSidebarService.getSidebar('manifoldPanel_'.concat((this._layoutService.manifoldPanelInstances + 1).toString()));
    if (panelExistsInRegistry == null) {
      this.createComponent(ManifoldPanelComponent, this._layoutService.manifoldPanelComponentRef);
      this._layoutService.componentType = componentType;
    } else {
      this._fuseSidebarService.getSidebar('manifoldPanel_'.concat((this._layoutService.manifoldPanelInstances + 1).toString())).open();
      this._layoutService.manifoldPanelInstances += 1;
      await this.createComponent(componentType, this._layoutService.manifoldPanelComponentRefMap.get(this._layoutService.manifoldPanelInstances), true);
      this.manageManifoldPanels();
      this.instantiateFusionForm();
    }
  }

  async createComponent(componentType: any, referenceHost: any, contentComponent?: boolean) {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(componentType);
    const compRef = await referenceHost.viewContainerRef.createComponent(componentFactory);
    compRef.instance.data = this._layoutService.manifoldPanelInputs.data;
    compRef.instance.key = this._layoutService.manifoldPanelInputs.entityKey;
    this.componentRef = compRef;
    if (contentComponent != null && contentComponent === true) {
      this._layoutService.manifoldPanelCompRef.set(this._layoutService.manifoldPanelInstances, compRef);
      this.instantiateFusionForm();
    }
    this._rendererFactory.createRenderer(null, null).setProperty(this._layoutService?.manifoldPanelTitleMap?.get(this._layoutService?.manifoldPanelInstances)?.nativeElement, 'innerHTML', this._layoutService?.manifoldPanelTitle.get(this._layoutService?.manifoldPanelInstances - 1));
  }

  setManifoldPanelInputs(data: ManifoldPanel) {
    this._layoutService.manifoldPanelInputs = new ManifoldPanel(data.componentSelector, data.moduleId, data.caption, data.size, data.data, data.entityKey, data.primaryAction, data.secondaryAction);
    this.setManifoldPanelTitle(data?.caption);
  }

  manageManifoldPanels() {
    const data = Array.from(this._layoutService.manifoldPanelSidebarMap.entries()).find(x => x[0] === this._layoutService.manifoldPanelInstances - 1)
    this._rendererFactory.createRenderer(null, null).removeClass(this._layoutService.manifoldPanelSidebarMap.get(data[0]), this._layoutService.manifoldPanelSizeMap.get(data[0]));
    this._rendererFactory.createRenderer(null, null).addClass(this._layoutService.manifoldPanelSidebarMap.get(data[0]), 'drawer-xx-lg');
    this._rendererFactory.createRenderer(null, null).setStyle(this._layoutService.manifoldPanelSidebarMap.get(data[0]), 'z-index', 1);
  }

  instantiateFusionForm() {
    (this.componentRef.instance as FusionFormComponent).data = this.componentRef.instance.data;
    (this.componentRef.instance as FusionFormComponent).key = this.componentRef.instance.key;
    if (this.componentRef.instance.fusionFormGroup != null) {
      this._layoutService.manifoldPanelFormMap.set(this._layoutService?.manifoldPanelInstances, this.componentRef?.instance?.fusionFormGroup);
      if (this._layoutService.manifoldPanelFormMap.get(this._layoutService?.manifoldPanelInstances)?.status.toLowerCase() === 'invalid')
        this._rendererFactory.createRenderer(null, null)
          .setAttribute(this._layoutService.manifoldPanelPrimaryActionMap.get(this._layoutService.manifoldPanelInstances), "disabled", 'true');
      this._layoutService.manifoldPanelFormMap.get(this._layoutService?.manifoldPanelInstances).valueChanges
        .subscribe(response => {
          if (this._layoutService.manifoldPanelFormMap.get(this._layoutService?.manifoldPanelInstances)?.status.toLowerCase() === 'invalid')
            this._rendererFactory.createRenderer(null, null)
              .setAttribute(this._layoutService.manifoldPanelPrimaryActionMap.get(this._layoutService.manifoldPanelInstances), "disabled", 'true');
          else
            this._rendererFactory.createRenderer(null, null)
              .removeAttribute(this._layoutService.manifoldPanelPrimaryActionMap.get(this._layoutService.manifoldPanelInstances), "disabled");
        });
    }
  }

  onPrimaryAction() {
    this.showActionProgress();
    (this._layoutService.manifoldPanelCompRef.get(this._layoutService.manifoldPanelInstances).instance as FusionFormAdapter).primaryAction();
  }

  onSecondaryAction() {
    this.showActionProgress();
    (this._layoutService.manifoldPanelCompRef.get(this._layoutService.manifoldPanelInstances).instance as FusionFormAdapter).secondaryAction();
  }


  closePanel(panelName: string) {
    this._fuseSidebarService.getSidebar(panelName).close(panelName);
    this.clearContentComponentRefHost();
    this._layoutService.manifoldPanelInstances--;
  }

  closeCurrentManifoldPanel() {
    if (this._layoutService.manifoldPanelInstances === 1) {
      this.closeAllManifoldPanels();
    } else {
      this.closePanel(`manifoldPanel_${this._layoutService.manifoldPanelInstances.toString()}`);
      this.renderPanelClasses();
      this.hideActionProgress();
    }
  }

  closeAllManifoldPanels() {
    this._layoutService.manifoldPanelInstances = 0;
    this._layoutService.manifoldPanelComponentRef.viewContainerRef.clear();
    this._layoutService.manifoldPanelTitle.clear();
    this._layoutService.manifoldPanelTitleMap.clear();
    this.hideActionProgress();
  }

  renderPanelClasses() {
    this._rendererFactory.createRenderer(null, null).removeClass(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      'drawer-xx-lg'
    );
    this._rendererFactory.createRenderer(null, null).removeStyle(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      'z-index'
    );
    this._rendererFactory.createRenderer(null, null).addClass(
      this._layoutService.manifoldPanelSidebarMap.get(
        this._layoutService.manifoldPanelInstances
      ),
      this._layoutService.manifoldPanelSizeMap.get(
        this._layoutService.manifoldPanelInstances
      )
    );
  }

  setManifoldPanelTitle(caption: string) {
    this._layoutService.manifoldPanelTitle.set(this._layoutService.manifoldPanelInstances, caption);
  }

  clearContentComponentRefHost() {
    const componentRefHost = this._layoutService.manifoldPanelComponentRefMap.get(this._layoutService.manifoldPanelInstances);
    componentRefHost?.viewContainerRef.clear();
  }

  showActionProgress() {
    this._fuseProgressBarService.show();
  }

  hideActionProgress() {
    this._fuseProgressBarService.hide();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET,FusionConfigService, HttpService, Path, Header } from '@exxat/fusion/core';
import { SchoolContact } from '@exxat/publicwebsite/models'

@Injectable()
export class SchoolContactApiClient extends HttpService {
  constructor(protected _fusionConfigService : FusionConfigService) {
    super();
  }

  protected getBaseUrl(): string {
    return this._fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<SchoolContact[]>('/Faculty')
  public getSchoolContact(@Header('TenantId') tenantId: string,
  @Header('Oucodes') oucodes: string, @Header('TenantName') tenantName): Observable<SchoolContact[]> {
    return null;
  }

}

import { FusionConfigService, GET, Header, HttpService } from '@exxat/fusion';
import { Observable } from 'rxjs';
import { WelcomeMsg } from '@exxat/publicwebsite/models';
import { Injectable } from '@angular/core';

@Injectable()
export class WelcomeMsgApiClient extends HttpService {
  constructor(protected fusionConfigService: FusionConfigService) {
    super();
  }

  getBaseUrl() {
    return this.fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<WelcomeMsg>('/WelcomeMsg')
  public getWelcomeMSg(
    @Header('TenantId') tenantId: string,
    @Header('OuCodes') ouCodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<WelcomeMsg> {
    return null;
  }
}

import { ComponentFactoryService, ReferenceAreaDirective, UtilityModule } from './services/index';
import { AuthSandbox } from './services/auth/auth.sandbox';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/auth/user.service';
import { ApplicationInsightsService } from "./services/application-insights/application-insights.service";
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppstoreModule } from './store/Appstore.module';
import { HttpResponseHandler } from './asyncServices/http/httpResponseHandler.service';
import { HttpServiceModule } from './asyncServices/http/http.module';
import { RealtimeService } from './asyncServices/communication/realtime.service';
import { EventsModule } from './event/events.module';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { A11yModule } from '@angular/cdk/a11y';
import { MsalService } from './services/auth/msal.service';
import { UserConsentSandbox } from './services/user-consent/user-consent.sandbox';
import { UserConsentApiClient } from './services/user-consent/user-consent.apiClient.service';

@NgModule({
  declarations: [
    ReferenceAreaDirective
  ],
  imports: [
    CommonModule,
    AppstoreModule,
    EventsModule,
    HttpServiceModule,
    UtilityModule,
    HttpClientModule,
    A11yModule,
    MatInputModule,
    MatSelectModule
  ],
  exports: [
    ReferenceAreaDirective,
    AppstoreModule,
    EventsModule,
    HttpServiceModule,
    UtilityModule
  ],
  providers: [
    AuthService,
    UserService,
    AuthSandbox,
    HttpResponseHandler,
    ComponentFactoryService,
    RealtimeService,
    MsalService,
    ApplicationInsightsService,
    UserConsentSandbox,
    UserConsentApiClient
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BaseCoreModule { }

import { createFeatureSelector, createSelector,ActionReducerMap } from '@ngrx/store';
import { UserState, UserReducer } from '../reducers/user.reducers';
import { OrgState, OrgReducer } from '../reducers/org.reducers';
import { TokenModel, OuCodeAccessTree, AccessedOuCode } from "@exxat/fusion/models";
export interface AppState {
  app: UserState;
  org: OrgState;
}

export const reducers: ActionReducerMap<AppState> = {
  app: UserReducer,
  org: OrgReducer
};

export const getUserState = createFeatureSelector<UserState>('app');

export const getOrgState = createFeatureSelector<OrgState>('org');

export const getAuthState = createSelector(
  getUserState,
  (state: UserState) => state.user !== null ? state.user.Token : new TokenModel()
);

export const selectedOucode = createSelector(
  getOrgState,
  (state: OrgState) => {
    return getSelectedOucode(state.TenantWithOuCodeTree.OucodeTree);
  }
)

export const  getUserAccessedOuCodes = createSelector(
  getOrgState,
  (state:OrgState) => {
    return getOucodeList([],state.TenantWithOuCodeTree.OucodeTree,'',0,'');
  }
)

export function getSelectedOucode(oucodeTree: OuCodeAccessTree[] | OuCodeAccessTree): OuCodeAccessTree {
  if (Array.isArray(oucodeTree)) {
    return selectedOucodeGet(oucodeTree);
  }
  else {
    if (oucodeTree.isSelected)
      return oucodeTree;
    else if (oucodeTree.hasOwnProperty('Children'))
      return getSelectedOucode(oucodeTree.Children);
  }
  return null;
}

export function selectedOucodeGet(oucodeTree){
  let selectedOuCode;

  for(let i=0;i<oucodeTree.length; i++)
  {
    const element = oucodeTree[i];
    if (element.isSelected) {
        selectedOuCode = element;
          return selectedOuCode;
    }
    else if (element.hasOwnProperty('Children') && element.Children.length > 0) {
      selectedOuCode = getSelectedOucode(element.Children);
      if(selectedOuCode!==undefined && selectedOuCode!==null){
      return selectedOuCode;
    }
    }
  }

  return null;

}

export function getOucodeList(accessedOuCodes:AccessedOuCode[],OucodeAccessTrees:OuCodeAccessTree[],OucodeStr:string,level:number,parent:string) {
    if(Array.isArray(OucodeAccessTrees)) {
      level=level+1;
      if(level>1){
        OucodeStr=(OucodeStr==='' ? '' : `${OucodeStr}-`)+parent;
      }
        OucodeAccessTrees.forEach((element) => {
        const oucodeWithName:AccessedOuCode = new AccessedOuCode();
        oucodeWithName.Oucode = element.Oucode;
        oucodeWithName.Name = element.Name;
        oucodeWithName.fullName = getFullName(level,OucodeStr,element);

        if(element.hasOwnProperty('Children') && element['Children'].length>0) {
          parent = getParentName(level, element, parent);
          getOucodeList(accessedOuCodes,element.Children,OucodeStr,level,parent);
        }
        else {
          accessedOuCodes.push(oucodeWithName);
        }

      });
    }
    
    return accessedOuCodes;
  }

  function getFullName(level, OucodeStr, element): string {
    return level > 2 ? ((OucodeStr==='' ? '' : `${OucodeStr}-`) + element.Name) : element.Name;
  }
  function getParentName(level, element, parent) {
    if (level > 1) {
       return element.Name;
    }
    return parent;
  }

  



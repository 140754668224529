import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExxatOverlayComponent } from './exxat-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [ExxatOverlayComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MaterialModule
  ]
})
export class ExxatOverlayModule { }

import { Injectable } from '@angular/core';
import { GET, Header, HttpService} from '@exxat/fusion';
import { Observable } from 'rxjs';
import {OrgUnit} from '@exxat/publicwebsite/models'

@Injectable({
    providedIn: 'root'
})
export class OuNameService extends HttpService{
    constructor(){
        super();
    }

    protected getBaseUrl(): string {
        return this.configService.getservice('publicwebsite').endpoint;
    }

    
  @GET<OrgUnit>('/OrgUnit')
  public getOuNames(
    @Header('TenantId') tenantId: string,
    @Header('Oucodes') oucodes: string,
    @Header('TenantName') tenantName: string
  ): Observable<OrgUnit[]> {
    return null;
  }
}
import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

export interface colorType {
  value: string;
  isDark: boolean;
}

export const colorTypes: Array<colorType> = [
  { value: 'red', isDark: false },
  { value: 'warn', isDark: false },
  { value: 'pink', isDark: true },
  { value: 'purple', isDark: true },
  { value: 'deep-purple', isDark: true },
  { value: 'accent', isDark: true },
  { value: 'primary', isDark: true },
  { value: 'indigo', isDark: true },
  { value: 'blue', isDark: false },
  { value: 'light-blue', isDark: false },
  { value: 'cyan', isDark: false },
  { value: 'teal', isDark: true },
  { value: 'green', isDark: true },
  { value: 'light-green', isDark: false },
  { value: 'lime', isDark: false },
  { value: 'yellow', isDark: false },
  { value: 'amber', isDark: false },
  { value: 'orange', isDark: false },
  { value: 'deep-orange', isDark: false },
  { value: 'brown', isDark: true },
  { value: 'fuse-white', isDark: false },
  { value: 'white', isDark: false },
  { value: 'fuse-black', isDark: true },
  { value: 'black', isDark: true },
];

@Directive({
  selector: '[fontColor]'
})
export class FontColorDirective implements AfterViewInit {
  private el: Element;
  private backgroundColor: string;
  private classList: Array<string>;

  constructor(private readonly elRef: ElementRef, private readonly renderer: Renderer2) {
    this.setElement();
  }

  ngAfterViewInit(): void {
    this.setElement();
    this.setBackGroundColor();
    this.setFontClass();
  }

  setElement() {
    if (this.elRef && this.elRef.nativeElement) {
      this.el = this.elRef.nativeElement;
    }
  }

  setBackGroundColor() {
    this.classList = this.el.getAttribute('class').split(' ');
    this.classList.forEach((element) => {
      if (element.endsWith('-bg')) {
        this.backgroundColor = element;
      }
    });
    this.backgroundColor = this.backgroundColor.substring(0, this.backgroundColor.indexOf('-bg')).toLowerCase();
  }

  setFontClass() {
    let color: colorType = colorTypes.find(x => x.value.toLowerCase() === this.backgroundColor)

    if (color && color.isDark) {
      this.renderer.removeClass(this.el, 'fuse-black-fg');
      this.renderer.addClass(this.el, 'fuse-white-fg');
    }
    else {
      this.renderer.removeClass(this.el, 'fuse-white-fg');
      this.renderer.addClass(this.el, 'fuse-black-fg');
    }
  }
}

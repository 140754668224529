import { OrgFacade } from './../../store/facades/org.facade';
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FusionConfigService } from './../../configuration/index';

import { FoundationInjector } from './../../injector/foundation-injector';
import { HttpResponseHandler } from './httpResponseHandler.service';
import { UserFacade } from './../../store/facades/user.facade';


/**
 * Supported @Produces media types
 */
export enum MediaType {
  JSON,
  FORM_DATA
}

@Injectable()
export class HttpService {

  protected httpClient: HttpClient;
  protected httpResponseHandler: HttpResponseHandler;
  protected configService: FusionConfigService;
  protected userFacade: UserFacade;
  protected orgFacade:OrgFacade;
  protected encryptionEnabled : boolean;
  public constructor() {
    this.httpClient = FoundationInjector.get(HttpClient);
    this.httpResponseHandler = FoundationInjector.get(HttpResponseHandler);
    this.configService = FoundationInjector.get(FusionConfigService);
    this.userFacade = FoundationInjector.get(UserFacade);
    this.orgFacade = FoundationInjector.get(OrgFacade);
    this.encryptionEnabled = this.configService.appSettings.Cryptography.EnableEncryption;
  }

  protected getBaseUrl(): string {
    this.encryptionEnabled = this.configService.appSettings.Cryptography.EnableEncryption;
    return this.configService.appSettings.auth.endpoint;
  }
  
  protected geGatewayUrl(): string {
    return this.configService.appSettings.gateway.endpoint;
  }

  protected getDefaultHeaders(): Object {
    return null;
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfigurationClient } from '@azure/app-configuration';
import { FUSION_CONFIG } from '@exxat/fusion';

const configURL = `${window.location.origin}/assets/config.json`;

fetch(configURL)
  .then((response) => response.json())
  .then((response) => {
    const connectionString = response.azAppConfig;

    getAzureAppConfig(connectionString).then((config) => {
      platformBrowserDynamic([
        { provide: FUSION_CONFIG, useValue: config },
      ]).bootstrapModule(AppModule);

      if (config.appSettings.production) {
        enableProdMode();
      }
    });
  })
  .catch((err) => console.error(err));

async function getAzureAppConfig(connectionString): Promise<any> {
  const client = new AppConfigurationClient(connectionString);

  const settings = await client.getConfigurationSetting({
    key: 'appSettings',
    label: 'public-webapp',
  });

  return JSON.parse(settings?.value);
}

import { getOrgState, selectedOucode, getUserAccessedOuCodes,AppState } from './../selectors/app.states';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SetTenantWithOucodes, GetTenantWithOucodes, UpdateTenantWithOucodes } from '../actions/org.actions';

import { OrgState } from '../reducers/org.reducers';
import { TenantWithOuCodeTree, OuCodeAccessTree, AccessedOuCode } from '@exxat/fusion/models';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class OrgFacade {

    OrgState$: Observable<OrgState>;
    selectedOucode$:Observable<OuCodeAccessTree>;
    userAccessedOucodes$:Observable<AccessedOuCode[]>;
    constructor(public appState$: Store<AppState>) {
        this.OrgState$ = this.appState$.select(getOrgState);
        this.selectedOucode$ = this.appState$.select(selectedOucode).pipe(distinctUntilChanged());
        this.userAccessedOucodes$ = this.appState$.select(getUserAccessedOuCodes).pipe(distinctUntilChanged());
    }

    SetTenantWithOucodes(payload: TenantWithOuCodeTree) {
        this.appState$.dispatch(new SetTenantWithOucodes(payload));
    }

    UpdateTenantWithOucodes(payload: TenantWithOuCodeTree) {
        this.appState$.dispatch(new UpdateTenantWithOucodes(payload));
    }

    GetTenantWithOucodes() {
        this.appState$.dispatch(new GetTenantWithOucodes());
    }
}

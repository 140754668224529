import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, OnDestroy, SimpleChanges } from '@angular/core';
import { merge, Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { FusionNavigationService } from '@exxat/fusion/services';
import { OrgFacade } from '@exxat/fusion/core';
import { publicNavigation } from '../../navigation/public-website.navigation';
import { MessageService } from '@exxat/publicwebsite/services';

@Component({
    selector       : 'fusion-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FusionNavigationComponent implements OnInit, OnDestroy
{

    @Input()
    navigation: any;
    navAliasLoaded$ = new BehaviorSubject<boolean>(true);
    // Private
    private _unsubscribeAll: Subject<boolean>;

    isOucodeSelected$: Observable<boolean>; 

    routePath$ = new Subject<boolean>();
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fusionNavigationService: FusionNavigationService,
        private _orgFacade: OrgFacade,
        private _messageService: MessageService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.isOucodeSelected$ = this._orgFacade.selectedOucode$.pipe(takeUntil(this._unsubscribeAll),map(x=> 
             x === (undefined) ? false:true
            ));
    }


    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        //this.navigation = this.navigation || this._fusionNavigationService.getCurrentNavigation();

        this.navigation = this.navigation || this._fusionNavigationService.getCurrentNavigationForPublicWebsite(publicNavigation);

        // Subscribe to the current navigation changes
        this._fusionNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fusionNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._fusionNavigationService.onPublicWebsiteNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.navigation = this._fusionNavigationService.getCurrentNavigationForPublicWebsite(publicNavigation);

            // Mark for check
            this._changeDetectorRef.markForCheck();
        })

        // Subscribe to navigation item
        merge(
            this._fusionNavigationService.onNavigationItemAdded,
            this._fusionNavigationService.onNavigationItemUpdated,
            this._fusionNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {
             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

         // Wait for layout component to be loaded before loading navigation menu
         this._messageService.onGetVisibilityConfiguration.subscribe(conf => {
             this.updateNavigationVisibility(conf);
             this.routePath$.next(true);
         });
         this.labelAliasListener();      
    }

    ngOnDestroy() : void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    labelAliasListener() {
        this._fusionNavigationService.aliasLabels
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((x) => {
          this.navAliasLoaded$.next(false);
          this._changeDetectorRef.detectChanges();
          this.navAliasLoaded$.next(true);
        });
    }

    updateNavigationVisibility(conf): void {
        if (!conf?.clinicalSchedule) {
            this._fusionNavigationService.removePublicNavigationItem('public-website.education-schedule');
        }
        if (!conf.resourcesGeneral && !conf.resourcesCourse) {
            this._fusionNavigationService.removePublicNavigationItem('public-website.resources-collapsable');
        }
        if (!conf.resourcesGeneral) {
            this._fusionNavigationService.removePublicNavigationItem('public-website.resources');
        }
        if (!conf?.resourcesCourse) {
            this._fusionNavigationService.removePublicNavigationItem('public-website.resources-course');
        }
        if (!conf?.clinicalContacts) {
            this._fusionNavigationService.removePublicNavigationItem('public-website.school-contact');
        }
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, Header, HttpService, Path } from '@exxat/fusion/core';
import { OrgUnit, Organization } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MetaApiClient extends HttpService {
  constructor() {
    super();
  }

  protected getBaseUrl(): string {
    return this.configService.getservice('publicwebsite').endpoint;
  }
  
  @GET<OrgUnit>('/Organization')
  public getOrganization(@Header('TenantId') tenantId: string,
  @Header('Oucodes') oucodes: string, @Header('TenantName') tenantName: string): Observable<Organization> {
    return null;
  }

  @GET<OrgUnit>('/TenantInformation')
  public getTenantInformation(@Header('TenantId') tenantId: string): Observable<Organization> {
    return null;
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FusionConfigService } from '@exxat/fusion/core';
import { DrawerService } from '../../components/drawer/drawer.service';
import { LayoutService } from './layout-1.service';
import { ManifoldPanelDirective } from '../../components/manifold-panel/manifold-panel.directive';
import { HeaderService } from '../../components/header/header.service';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(ManifoldPanelDirective, { static: false })
  manifoldPanelRefHost: ManifoldPanelDirective;

  fusionConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  drawerSize: string;

  constructor(
    private _fusionConfigService: FusionConfigService,
    private _drawerService: DrawerService,
    private renderer: Renderer2,
    private _layoutService: LayoutService,
    private headerService: HeaderService
  ) {
    // Set the defaults

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this._fusionConfigService.uiSettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fusionConfig = config;
      });
    this._drawerService.drawerSize
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(response => {
        const a = this.renderer;
        this.drawerSize = response;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.headerService.setWindowHeight(event.target.innerHeight);
  }
  
  ngAfterViewInit(): void {
    this._layoutService.manifoldPanelComponentRef = this.manifoldPanelRefHost;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

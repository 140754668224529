import { Injectable } from '@angular/core';
import {
  Category,
  CategoryResources,
  Course,
  CourseDocument,
} from '@exxat/publicwebsite/models';
import { ResourcesApiClient } from '@exxat/publicwebsite/services';
import { Observable } from 'rxjs';

@Injectable()
export class ResourcesSandBox {
  constructor(private resourcesApiClient: ResourcesApiClient) {}

  public getResources(tenantId, ouCode, tenantName): Observable<Category[]> {
    return this.resourcesApiClient.getResources(tenantId, ouCode, tenantName);
  }

  public getCourses(tenantId, ouCode, tenantName): Observable<Course[]> {
    return this.resourcesApiClient.getCourses(tenantId, ouCode, tenantName);
  }

  public checkFileExistForFileCollectionKey(
    tenantId,
    ouCode,
    tenantName,
    fileCollectionKeys
  ): Observable<string[]> {
    return this.resourcesApiClient.checkFileExistForFileCollectionKey(
      tenantId,
      ouCode,
      tenantName,
      fileCollectionKeys
    );
  }

  public getCourseCategories(
    tenantId,
    ouCode,
    tenantName,
    courseId
  ): Observable<CourseDocument[]> {
    return this.resourcesApiClient.getCourseCategories(
      tenantId,
      ouCode,
      tenantName,
      courseId
    );
  }

  public getCategoryResources(
    tenantId,
    ouCode,
    tenantName,
    courseId,
    categoryId
  ): Observable<CategoryResources[]> {
    return this.resourcesApiClient.getCategoryResources(
      tenantId,
      ouCode,
      tenantName,
      courseId,
      categoryId
    );
  }
}

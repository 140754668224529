import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { FusionConfigService } from './../../configuration/fusion-config.service';
import { TokenModel, UserRefreshTokenModel } from '@exxat/fusion/models';
import { UserFacade } from './../../store/facades/user.facade';
import { take } from 'rxjs/operators';
import { NotificationService } from './../../services/utility/notification.service';

@Injectable()
export class HttpResponseHandler {
  constructor(
    private readonly router: Router,
    private notificationService: NotificationService,
    private readonly userFacade: UserFacade,
    private readonly configService: FusionConfigService
  ) {}

  public onCatch(response: any, source: Observable<any>): Observable<any> {
    switch (response.status) {
      case 400:
        this.handleBadRequest(response);
        break;

      case 401:
        this.handleUnauthorized(response);
        break;

      case 403:
        this.handleForbidden(response);
        break;

      case 404:
        this.handleNotFound(response);
        break;

      default:
        if (
          response.error?.Status != null &&
          typeof response.error.Status === 'object'
        )
          this.handleServerError(response.error?.Status);
        else this.handleServerError(response);
        break;
    }

    return throwError(response);
  }

  public handleActonResponse(response: any) {
    if (response?.status != null && typeof response.status === 'object') {
      if (response.status.message) {
        response.status.statusText = response.status.message;
        this.notificationService.displayNotification(
          response.status,
          response.status.responseType,
          'Success'
        );
      }
    }
  }

  // /**
  //  * Shows notification errors when server response status is 401
  //  *
  //  * @param error
  //  */
  private handleBadRequest(responseBody: any): void {
    if (responseBody._body) {
      try {
        const bodyParsed = responseBody.json();
        this.handleErrorMessages(bodyParsed);
      } catch (error) {
        this.handleServerError(responseBody);
      }
    } else {
      this.handleServerError(responseBody);
    }
  }

  // /**
  //  * Shows notification errors when server response status is 401 and redirects user to login page
  //  *
  //  * @param responseBody
  //  */
  private handleUnauthorized(responseBody: any): void {
    responseBody.ShowItAsToaster = true;
    this.notificationService.displayNotification(
      responseBody,
      'error',
      'Your session has expired please login again'
    );
    // Read configuration in order to see if we need to display 401 notification message
    this.userFacade.UserState$.pipe(take(1)).subscribe((state) => {
      if (state != null && state.isAuthenticated === true) {
        const tokenError = responseBody.headers.get('identity_token_expired');
        if (tokenError != null && tokenError === 'bearer_accessToken_expired') {
          this.renewRequestCall();
        } else if (
          tokenError != null &&
          tokenError === 'bearer_lifetime_expired'
        ) {
          this.userFacade.LogOut();
        } else if (
          tokenError != null &&
          tokenError === 'azureAD_token_expired'
        ) {
          alert('Your session has expired. Please login again.');
          this.userFacade.AzureLogout();
        }
      }
    });
  }

  private renewRequestCall() {
    if (sessionStorage.getItem('identity.token.renew.request') !== 'Sent') {
      this.userFacade.AuthState$.pipe(take(1)).subscribe(
        (tokenModel: TokenModel) => {
          const token = tokenModel.refreshToken;
          if (token) {
            sessionStorage.setItem('identity.token.renew.request', 'Sent');
            this.userFacade.Login(new UserRefreshTokenModel(token));
          }
        }
      );
    }
  }

  // /**
  //  * Shows notification errors when server response status is 403
  //  */
  private handleForbidden(responseBody: any): void {
    responseBody.ShowItAsToaster = true;
    this.notificationService.displayNotification(
      responseBody,
      'error',
      "You don't have permission to access. Contact your administrator"
    );
    const subscription_required = responseBody.headers.get(
      'subscription_required'
    );
    if (subscription_required != null && subscription_required === 'true') {
      this.router.navigateByUrl('/payment/subscriptions');
    }
  }

  // /**
  //  * Shows notification errors when server response status is 404
  //  *
  //  * @param responseBody
  //  */
  private handleNotFound(responseBody: any): void {
    // Read configuration in order to see if we need to display 401 notification message
    responseBody.ShowItAsToaster = true;
    this.notificationService.displayNotification(
      responseBody,
      'error',
      'Resource not found'
    );
    let notFoundEndpoints: Array<string> =
      this.configService.appSettings.notifications.unauthorizedEndpoints;
    if (notFoundEndpoints) {
      notFoundEndpoints = notFoundEndpoints.filter(
        (endpoint) => this.getRelativeUrl(responseBody.url) === endpoint
      );
    }
    console.log(notFoundEndpoints);
  }

  // /**
  //  * Shows notification errors when server response status is 500
  //  */
  private handleServerError(response: any): void {
    if (response?.Message) {
      response.statusText = response.Message;
      if (response.ResponseType) {
        this.notificationService.displayNotification(
          response,
          response.ResponseType,
          'Contact to respective person'
        );
      }
    }
  }

  // /**
  //  * Parses server response and shows notification errors with translated messages
  //  *
  //  * @param response
  //  */
  private handleErrorMessages(response: any): void {
    if (!response) {
      return;
    }
    for (const key of Object.keys(response)) {
      if (Array.isArray(response[key])) {
        response[key].forEach((value) =>
          this.notificationService.displayNotification(
            this.getTranslatedKey(value),
            'error'
          )
        );
      } else {
        this.notificationService.displayNotification(
          this.getTranslatedKey(response[key]),
          'error'
        );
      }
    }
  }

  // /**
  //  * Extracts and returns translated value from server response
  //  *
  //  * @param value
  //  */
  private getTranslatedKey(key: string): string {
    if (key.indexOf('[') > -1) {
      key = key.substring(key.lastIndexOf('[') + 1, key.lastIndexOf(']'));
    }
    return key;
  }

  // /**
  //  * Returns relative url from the absolute path
  //  *
  //  * @param responseBody<<<<
  //  */
  private getRelativeUrl(url: string): string {
    return url.toLowerCase().replace(/^(?:\/\/|[^\/]+)*\//, '');
  }
}

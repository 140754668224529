import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ManifoldPanel } from '../../components/manifold-panel/manifold-panel';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  drawerTitle: BehaviorSubject<string>;
  drawerSize: BehaviorSubject<string>;
  exxatCloseDrawerAction$: Subject<any>;
  isFormValid$: BehaviorSubject<boolean>;
  showPrimaryActionSpinner$: BehaviorSubject<boolean>;
  componentRef;
  referenceHost;
  primaryAction;
  secondaryAction;
  manifoldPanelComponentRef;
  manifoldPanelInstances = 0;
  manifoldPanelContentRef;
  manifoldpanelWidth;
  manifoldPanelInputs: ManifoldPanel;
  manifoldPanelTitle = new Map<number, string>();
  manifoldPanelTitleMap = new Map<number, any>();
  manifoldPanelSizeMap = new Map<number, string>();
  manifoldPanelSidebarMap = new Map<number, any>();
  manifoldPanelComponentRefMap = new Map<number, any>();
  manifoldPanelCompRef = new Map<number, any>();
  manifoldPanelFormMap = new Map<number, FormGroup>();
  manifoldPanelPrimaryActionMap = new Map<number, any>();
  componentType: string;

  constructor() {
    this.drawerTitle = new BehaviorSubject(null);
    this.drawerSize = new BehaviorSubject(null);
    this.exxatCloseDrawerAction$ = new Subject();
    this.manifoldPanelInputs = new ManifoldPanel(null, null, null, null, null, null, null, null);
    this.isFormValid$ = new BehaviorSubject(false);
    this.showPrimaryActionSpinner$ = new BehaviorSubject(false);
  }

}

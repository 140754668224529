import {
  UserInputModel,
  User,
  TokenModel,
  HashDataModel,
  NavigationChangeDetector,
  LaunchDataModel,
  OrgUnit,
  Organization
} from '@exxat/fusion/models';
import { Injectable } from '@angular/core';
import { GET, Header, Path } from './../../asyncServices/http/http.decorator';
import { HttpService } from './../../asyncServices/http/http.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Cryptography } from '../../helper/cryptography/encryption';

@Injectable()
export class AuthService extends HttpService {
  private readonly BASE_URL: string = '';
  public redirectUrl: string;

  constructor() {
    super();
    this.BASE_URL = this.configService.appSettings.auth.endpoint;
  }
  protected getBaseUrl(): string {
    return this.configService.getservice('foundation.meta').endpoint;
  }

  login(userInputModel: UserInputModel): Observable<any> {
    let url = `${this.BASE_URL}/account/login`;
    if (!this.BASE_URL) {
      url = `${this.configService.appSettings.auth.endpoint}/account/login`;
    }
    let requestBody: any = userInputModel;
    if (this.configService.appSettings.Cryptography.EnableEncryption) {
      requestBody = Cryptography.encryptRequest<UserInputModel>(
        userInputModel,
        this.configService
      );
    }
    return this.httpClient.post<User>(url, requestBody);
  }

  @GET('/launch')
  Launch(
    @Header('TenantId') header: string,
    @Header('Oucodes') oucodes: string
  ): Observable<LaunchDataModel[]> {
    return null;
  }

  @GET('/role/{hash}')
  public Role(
    @Header('TenantId') header: string,
    @Header('Oucodes') oucodes: string,
    @Path('hash') hash: string
  ): Observable<HashDataModel[]> {
    return null;
  }
  @GET('/rolenavigation/{hash}')
  public Navigation(
    @Header('TenantId') header: string,
    @Header('Oucodes') oucodes: string,
    @Path('hash') hash: string
  ): Observable<NavigationChangeDetector> {
    return null;
  }
  signUp(username: string, password: string): Observable<TokenModel> {
    const url = `${this.BASE_URL}/register`;
    return this.httpClient.post<TokenModel>(url, {
      username: username,
      password: password,
      GrantType: 'password'
    });
  }

  adSignUp(user: any, tokenModel: TokenModel): Observable<any> {
    const url = `${
      this.configService.getservice('foundation.registration').endpoint
    }/Registration/ADUserRegister`;

    return this.httpClient.post(url, user);
  }

  Logout(refreshToken: string, lastLogintime: string): Observable<any> {
    let url = `${this.BASE_URL}/account/logout`;
    if (!this.BASE_URL) {
      url = `${this.configService.appSettings.auth.endpoint}/account/logout`;
    }

    let params: HttpParams = new HttpParams();
    params = params.append('refreshToken', refreshToken);
    params = params.append('lastLogintime', lastLogintime);
    return this.httpClient
      .get<any>(url, { params: params })
      .pipe(
        catchError((error, source) =>
          this.httpResponseHandler.onCatch(error, source)
        )
      );
  }

  parseJwtToken(token: string): any {
    if (token !== undefined) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64));
    }
    return undefined;
  }

  @GET<OrgUnit>('/OrgUnit/{id}')
  public getOrgUnit(@Path('id') id: any): Observable<OrgUnit> {
    return null;
  }

  @GET<OrgUnit>('/Organization/{id}')
  public getOrganization(@Path('id') id: any): Observable<Organization> {
    return null;
  }
}

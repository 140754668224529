import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VisibilityConfigurationModel } from '../models/visibility-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject = new Subject<any>();
  private readonly _getVisibilityConfiguration: Subject<any>;
  public visibilityConfiguration : VisibilityConfigurationModel;

  constructor(){
    this._getVisibilityConfiguration = new Subject();
  }

  get onGetVisibilityConfiguration(): Observable<VisibilityConfigurationModel> {
    return this._getVisibilityConfiguration.asObservable();
  }

  sendMessage() {
    this.subject.next();
  }  

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  
  sendVisibilityConfiguration(configuration: VisibilityConfigurationModel) {
    this._getVisibilityConfiguration.next(configuration);
  }
}

import { OrgActions, OrgActionTypes } from '../actions/org.actions';
import {
  OuCode,
  TenantWithOuCodeTree,
  OuCodeAccessTree,
} from '@exxat/fusion/models';

export interface OrgState {
  TenantWithOuCodeTree: TenantWithOuCodeTree;
}
let tenantId = sessionStorage.getItem('TenantId');
let oucodes: OuCodeAccessTree[] = JSON.parse(sessionStorage.getItem('Oucodes'));
if (!tenantId) {
  tenantId = sessionStorage.getItem('TenantId');
  if (tenantId) sessionStorage.setItem('TenantId', tenantId);
}
if (!oucodes) {
  oucodes = JSON.parse(sessionStorage.getItem('Oucodes'));
  if (oucodes) sessionStorage.setItem('Oucodes', JSON.stringify(oucodes));
}

export const initialMetaState: OrgState = {
  TenantWithOuCodeTree: {
    TenantId: tenantId ? tenantId : 'Base',
    OucodeTree: oucodes
      ? oucodes
      : [
          {
            Name: 'Base',
            Oucode: '1000',
            fullName: '',
            isSelected: false,
            Children: [],
          },
        ],
  },
};

export function OrgReducer(
  state = initialMetaState,
  action: OrgActions
): OrgState {
  switch (action.type) {
    case OrgActionTypes.SetTenantWithOucodes:
    case OrgActionTypes.UpdateTenantWithOucodes: {
      state = { ...state, TenantWithOuCodeTree: action.payload };
      return state;
    }
    case OrgActionTypes.GetTenantWithOucodes:
    default: {
      return {
        ...state,
      };
    }
  }
}

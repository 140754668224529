import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sandbox } from '@exxat/fusion';
import { MetaApiClient } from './meta.ApiClient';
import { OrgUnit, Organization } from '@exxat/publicwebsite/models';


@Injectable({
    providedIn: 'root'
})
export class MetaSandbox extends Sandbox {
    constructor(private metaApiClient: MetaApiClient
    ) {super();}

    public getOrganization(tenantId,oucode, tenantName): Observable<Organization> {
        return this.metaApiClient.getOrganization(tenantId,oucode, tenantName);
    }
    
    public getTenantInformation(tenantId): Observable<Organization> {
        return this.metaApiClient.getTenantInformation(tenantId);
    }
}
import { NgModule } from '@angular/core';
import { RoleNavigationService } from './role.navigation.service';


@NgModule({
  declarations: [],
  imports: [],
  providers: [
    RoleNavigationService
  ],


  bootstrap: []
})
export class RoleNavigationModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ErrorComponent } from 'libs/publicwebsite/src/lib/public/error-404/error-404.component';
import { NotFoundComponent } from './not-found.component';
import { TenantNameResolver } from './tenant-name.resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren:() => import('@exxat/publicwebsite').then(m => m.PublicwebSiteModule),
    resolve: {
      orgData : TenantNameResolver
    }
  },
  // { path: '', component: NotFoundComponent },
  { path: '**', component: ErrorComponent },
]

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes,routingConfiguration)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }

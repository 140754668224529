import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
  Injector,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import {
  setAppInjector,
  FUSION_CONFIG,
  LoggingService,
} from '@exxat/fusion/core';
import {
  RoleNavigationModule,
  ExportExcelModule,
} from '@exxat/fusion/services';
import { CustomDirectiveModule } from './directives/directives.module';
import { DelegatorComponent } from './components/delegate/delegator.component';
import { UserConsentComponent } from './components/user-consent/user-consent.component';
import { ExxatAgreementComponent } from './components/user-consent/exxat-agreement/exxat-agreement.component';

const appRoutes: Routes = [
  { path: 'gateway/delegator', component: DelegatorComponent },
  { path: 'UserConsent', component: UserConsentComponent },
  {
    path: 'ExxatAgreement/PrivacyPolicy',
    component: ExxatAgreementComponent,
    data: { id: 'privacypolicy' },
  },
  {
    path: 'ExxatAgreement/TermsOfUse',
    component: ExxatAgreementComponent,
    data: { id: 'termsofuse' },
  },
];

@NgModule({
  imports: [
    CommonModule,
    CustomDirectiveModule,
    RouterModule,
    RoleNavigationModule,
    ExportExcelModule,
    RouterModule.forChild(appRoutes),
  ],
  declarations: [],
  providers: [],
  exports: [CustomDirectiveModule],
})
export class FusionModule {
  constructor(
    injector: Injector,
    @Optional() @SkipSelf() parentModule: FusionModule
  ) {
    setAppInjector(injector);
    LoggingService.setApplicationInsights();
    if (parentModule) {
      throw new Error(
        'FuseModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  public static setConfig(config): ModuleWithProviders<FusionModule> {
    return {
      ngModule: FusionModule,
      providers: [
        {
          provide: FUSION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}

import { Injectable, Inject, InjectionToken } from '@angular/core';
import {AppSettings, ServiceEndpoint} from './types/app-settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { UiSettings } from './types/ui-settings';
import { FusionConfig } from './types/fusion.config';
import * as _ from 'lodash';
import { Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';

export const FUSION_CONFIG = new InjectionToken('fusionCustomConfig');


@Injectable({
  providedIn:"root"
})
export class FusionConfigService {

  public appSettings: AppSettings;
  private _uiSettingsSubject: BehaviorSubject<UiSettings>;
  public readonly _defaultUiSettings: UiSettings;

  constructor( private readonly _platform: Platform, private readonly _router: Router, @Inject(FUSION_CONFIG) private readonly _config:FusionConfig) {
    this.appSettings = _config.appSettings;
    this._defaultUiSettings = _config.uiSettings;

    this._init();
  }


  getservice(serviceName: string): ServiceEndpoint {
    return this.appSettings.services[serviceName];
  }

  get(key: any) {
    return this.appSettings[key];
  }

  set uiSettings(value)
  {
        let config = this._uiSettingsSubject.getValue();
        config = _.merge({}, config, value);
        this._uiSettingsSubject.next(config);
  }

  get uiSettings(): any | Observable<UiSettings>
  {
      return this._uiSettingsSubject.asObservable();
  }

  private _init(): void
  {

      if ( this._platform.ANDROID || this._platform.IOS )
      {
          this._defaultUiSettings.customScrollbars = false;
      }

      this._uiSettingsSubject = new BehaviorSubject(_.cloneDeep(this._defaultUiSettings));

      // Reload the default layout config on every RoutesRecognized event
      // if the current layout config is different from the default one
      this._router.events
          .pipe(filter(event => event instanceof RoutesRecognized))
          .subscribe(() => {
              if ( !_.isEqual(this._uiSettingsSubject.getValue().layout, this._defaultUiSettings.layout) )
              {

                  const config = _.cloneDeep(this._uiSettingsSubject.getValue());
                  config.layout = _.cloneDeep(this._defaultUiSettings.layout);
                  this._uiSettingsSubject.next(config);
              }
          });
  }

  setConfig(value, opts = {emitEvent: true}): void
  {
      let config = this._uiSettingsSubject.getValue();
      config = _.merge({}, config, value);
      if ( opts.emitEvent === true )
      {
          this._uiSettingsSubject.next(config);
      }
  }

  getConfig(): Observable<UiSettings>
  {
      return this._uiSettingsSubject.asObservable();
  }


  resetToDefaults(): void
  {
      this._uiSettingsSubject.next(_.cloneDeep(this._defaultUiSettings));
  }

}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ClinicalEducationSchedule, ClinicalEducationSchedulePT} from '@exxat/publicwebsite/models'

import {
  GET,
  FusionConfigService,
  HttpService, Header
} from '@exxat/fusion/core';

@Injectable()
export class ClinicalEducationScheduleApiClient extends HttpService {

  constructor(protected _fusionConfigService : FusionConfigService) { 
    super();
  }

  getBaseUrl(){
    return this._fusionConfigService.getservice('publicwebsite').endpoint;
  }

  @GET<ClinicalEducationSchedule>('/ClinicalEducationSchedule')
  public getRotationDetails(@Header('TenantId') tenantId: string,
  @Header('Oucodes') oucodes: string, @Header('TenantName') tenantName: string) : Observable<ClinicalEducationSchedule[]>{
    return null;
  }

  @GET<ClinicalEducationSchedulePT>('/ClinicalEducationSchedule/PT')
  public getRotationDetailsPT(@Header('TenantId') tenantId: string,
  @Header('Oucodes') oucodes: string, @Header('TenantName') tenantName: string) : Observable<ClinicalEducationSchedulePT[]>{
    return null;
  }
}

import { Injectable } from "@angular/core";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, Router, ResolveEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { FusionConfigService } from "./../../configuration/index";
import { filter } from 'rxjs/operators';

@Injectable()
export class ApplicationInsightsService {

  private readonly routerSubscription: Subscription

  private readonly appInsights;


  constructor(private readonly router: Router,private readonly config: FusionConfigService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.config.appSettings.instrumentationKey
      }
    });
     this.appInsights.loadAppInsights();
     this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
       const activatedComponent = this.getActivatedComponent(event.state.root);
       if (activatedComponent) {
         this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
       }
     });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  logPageView(name?: string, uri?: string) {

    this.appInsights.trackPageView({ name, uri });
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {

    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  setUserName(userName: string) {
    this.appInsights.setAuthenticatedUserContext(userName);
  }

  clearUserName() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logEvent(name: string, properties?: any, measurements?: any) {
      this.appInsights.trackEvent(name, properties, measurements);
    }
    logException(
      exception: Error,
      handledAt?: string,
      properties?: any,
      measurements?: any
    ) {
      this.appInsights.trackException(exception, handledAt, properties, measurements);
    }
    logTrace(message: string, properties?: any, severityLevel?: any) {
      this.appInsights.trackTrace(message, properties, severityLevel);
    }
}

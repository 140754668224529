import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, defer, fromEvent } from 'rxjs';
import { tap, map, switchMap, catchError, filter } from 'rxjs/operators';

import { TokenModel } from '@exxat/fusion/models';
import { AuthService } from './../../services/auth/auth.service';
import { ApplicationInsightsService } from './../../services/index';
import {
  UserActionTypes,
  Login,
  LoginSuccess,
  LoginFailure,
  SignUp,
  SignUpSuccess,
  SignUpFailure,
  UpdateUserSuccess,
  AzureLogin,
  Logout,
} from '../actions/user.actions';
import {
  OrgActionTypes,
  LaunchSuccess,
  LaunchFailure,
  SetTenantWithOucodes,
  UpdateTenantWithOucodes,
} from '../actions/org.actions';
import { UserFacade } from '../facades/user.facade';
import { MsalService } from '../../services/auth/msal.service';
import { UserService } from '../../services/auth/user.service';
import { FusionConfigService } from '../../configuration/fusion-config.service';
import { UserConsentSandbox } from '../../services/user-consent/user-consent.sandbox';

@Injectable()
export class AuthEffects {
  private lastLogintime: any;
  private readonly flag;
  private tokenModel: TokenModel;
  private readonly launchUrl: string;
  private readonly loginUrl: string;
  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly msalService: MsalService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly userFacade$: UserFacade,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly config: FusionConfigService,
    private readonly userConsentSandbox: UserConsentSandbox
  ) {
    this.launchUrl = this.config.get('authGuardSettings').launchUrl;
    this.loginUrl = this.config.get('authGuardSettings').loginUrl;
  }

  // init$: Observable<Action> = createEffect(() => defer(() => {

  //   this.userFacade$.AuthState$
  //     .pipe(
  //       map((authdata) => {
  //         if (authdata) {
  //           return { user: this.userFacade$.UserState$, auth: authdata }
  //         }
  //         return null;
  //       }
  //       ),
  //       switchMap((payload) => {
  //         if(payload===null){
  //           return  of(new LoginFailure());
  //         }

  //         const state = {
  //           auth: payload.auth,
  //           user: payload.user
  //         };
  //         return  of(new Login(state))
  //       }),
  //       catchError((errorData) => {
  //         return of(new LoginFailure({ status: errorData.status, statusText: errorData.statusText, error: errorData.error }));
  //       })
  //     )
  // }));

  Login: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(UserActionTypes.LogIn),
      map((action: Login) => action.payload),
      switchMap((payload: any) => {
        return this.authService.login(payload).pipe(
          map((response: TokenModel) => {
            const tokenData = this.authService.parseJwtToken(
              response.accessToken
            );
            if (tokenData !== undefined) {
              const userData = JSON.parse(
                tokenData[
                  'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'
                ]
              );
              return new LoginSuccess({
                auth: response,
                user: userData,
                targetUrl: this.authService.redirectUrl,
              });
            }
            return null;
          }),
          catchError((errorData) => {
            return of(
              new LoginFailure({
                status: errorData.status,
                statusText: errorData.statusText,
                error: errorData.error,
              })
            );
          })
        );
      })
    )
  );

  AzureLogin: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.AzureLogin),
        map((action: AzureLogin) => action),
        tap(() => {
          this.msalService.login();
        })
      ),
    { dispatch: false }
  );

  LoginSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.LogInSuccess),
        tap((data: LoginSuccess) => {
          const token: TokenModel = data.payload.auth;
          sessionStorage.setItem(
            'CurrentLoginTime',
            JSON.stringify(new Date())
          );
          sessionStorage.setItem('Auth', JSON.stringify(token));
          sessionStorage.setItem('identity.token.renew.request', 'Completed');
          sessionStorage.setItem('User', JSON.stringify(data.payload.user));
          sessionStorage.setItem(
            'LastLoginTime',
            data.payload.user?.LastLoginTime
          );

          this.applicationInsightsService.setUserName(
            data.payload.user.UserName
          );

          this.userConsentSandbox.getConsent().subscribe((consentResponse) => {
            const targetUrl: string = data.payload.targetUrl;
            const tagetUrlendpoint = `?targetUrl=${encodeURIComponent(
              targetUrl
            )}`;
            if (
              consentResponse?.consented ||
              data.payload.user.UserName.toLowerCase().endsWith('.anonymous')
            ) {
              if (consentResponse != null) {
                sessionStorage.setItem(
                  'user.consent.consentedAt',
                  consentResponse.consentedAt
                );
              } else {
                sessionStorage.setItem('user.consent.consentedAt', 'delegator');
              }
              this.router.navigateByUrl(
                `${this.launchUrl}${targetUrl ? tagetUrlendpoint : ''}`
              );
            } else {
              this.router.navigateByUrl(
                `UserConsent${targetUrl ? tagetUrlendpoint : ''}`
              );
            }
          });
        }),
        catchError((error) => {
          console.log(error);
          this.applicationInsightsService.clearUserName();
          return of(new LaunchFailure({ error: error }));
        })
      ),
    { dispatch: false }
  );

  LoginFailure: Observable<any> = createEffect(
    () => this.actions.pipe(ofType(UserActionTypes.LogInFailure)),
    { dispatch: false }
  );

  SignUp: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(UserActionTypes.SignUp),
      map((action: SignUp) => action.payload),
      switchMap((payload) => {
        return this.authService.signUp(payload.Email, payload.Password).pipe(
          map((auth) => {
            return new SignUpSuccess({ Token: auth, Email: payload.Email });
          }),
          catchError((error) => {
            return of(new SignUpFailure({ error: error }));
          })
        );
      })
    )
  );

  SignUpSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.SignUpSuccess),
        tap((user: any) => {
          sessionStorage.setItem('Auth', JSON.stringify(user.payload.Token));
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  SignUpFailure: Observable<any> = createEffect(
    () => this.actions.pipe(ofType(UserActionTypes.SignUpFailure)),
    { dispatch: false }
  );

  public Logout: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.Logout),
        tap(() => {
          this.tokenModel = JSON.parse(sessionStorage.getItem('Auth'));
          this.lastLogintime = JSON.parse(
            sessionStorage.getItem('CurrentLoginTime')
          );
          sessionStorage.removeItem('Auth');
          sessionStorage.clear();
          sessionStorage.clear();
          this.applicationInsightsService.clearUserName();
        }),
        switchMap((_) => {
          let refreshToken;
          if (this.tokenModel != null) {
            refreshToken = this.tokenModel.refreshToken;
          } else {
            refreshToken = '';
          }
          this.router.navigateByUrl(this.loginUrl);
          return this.authService.Logout(refreshToken, this.lastLogintime);
        })
      ),
    { dispatch: false }
  );

  public AzureLogout: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.AzureLogout),
        tap(() => {
          this.msalService.logout();
          sessionStorage.clear();
          sessionStorage.clear();
          this.router.navigateByUrl(this.loginUrl);
        })
      ),
    { dispatch: false }
  );

  public UpdateUser: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(UserActionTypes.UpdateUser),
      switchMap((_) => {
        const user = JSON.parse(sessionStorage.getItem('User'));
        //bellow two condition of user.id for ternery operator are not same please check the case sensitivity of id
        return this.userService
          .UpdateUser(
            +(user.Id === undefined ? user.id : user.Id),
            'Base',
            '1000'
          )
          .pipe(
            map((data) => {
              return new UpdateUserSuccess({ user: data });
            }),
            catchError((error) => {
              return of(new SignUpFailure({ error: error }));
            })
          );
      })
    )
  );

  public UpdateUserSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(UserActionTypes.UpdateUserSuccess),
        tap((data: any) => {
          sessionStorage.setItem('User', JSON.stringify(data.payload));
        })
      ),
    { dispatch: false }
  );

  public LaunchSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrgActionTypes.LaunchSuccess),
        tap((data: LaunchSuccess) => {
          this.router.navigateByUrl('/account/launch');
        })
      ),
    { dispatch: false }
  );

  public LaunchFailure: Observable<any> = createEffect(
    () => this.actions.pipe(ofType(OrgActionTypes.LaunchFailure)),
    { dispatch: false }
  );

  public SetTenantWithOucodes: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrgActionTypes.SetTenantWithOucodes),
        tap((data: SetTenantWithOucodes) => {
          sessionStorage.setItem('TenantId', data.payload.TenantId);
          sessionStorage.setItem(
            'Oucodes',
            JSON.stringify(data.payload.OucodeTree)
          );
          sessionStorage.setItem('TenantId', data.payload.TenantId);
          sessionStorage.setItem(
            'Oucodes',
            JSON.stringify(data.payload.OucodeTree)
          );
        })
      ),
    { dispatch: false }
  );

  public UpdateTenantWithOucodes: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrgActionTypes.UpdateTenantWithOucodes),
        tap((data: UpdateTenantWithOucodes) => {
          sessionStorage.setItem('TenantId', data.payload.TenantId);
          sessionStorage.setItem(
            'Oucodes',
            JSON.stringify(data.payload.OucodeTree)
          );
        })
      ),
    { dispatch: false }
  );

  public onChange = createEffect(() =>
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event) => event.key === 'Auth' && event.newValue == null),
      map((event) => {
        return new Logout();
      })
    )
  );
}

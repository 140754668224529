import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map, tap, delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { FuseSidebarService } from '../../../components/sidebar/sidebar.service';

import { productNavigation } from '../../../navigation/navigation';
import {
  FusionConfigService,
  UserFacade,
  OrgFacade,
  AuthService,
  GlobalVariable,
} from '@exxat/fusion/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TechnicalSupportPopupComponent } from './technical-support-popup/technical-support-popup.component';
import { PageFacade } from '../../../store/facade';
import { HelpCenterPopupComponent } from './help-center-popup/help-center-popup.component';
import { ConsentDetailsPopupComponent } from './consent-details-popup/consent-details-popup.component';
import { MessageService, OuNameService } from '@exxat/publicwebsite/services';
import { FusionNavigationService, LookupAPIClientService } from '@exxat/fusion';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  selectedLanguage: any;
  lastLoginTime: any = new Date();
  // Private
  private _unsubscribeAll: Subject<any>;
  loggedInUser$: Observable<any>;
  tenantId$: Observable<string>;
  selectedOucodeName$: Observable<string>;
  hasDelegateRole$: Observable<boolean>;
  pageTitle: string;
  tenantID: string;
  ouCode: string;
  tenantName: string;
  /**
   * Constructor
   *
   */ constructor(
    private _fusionConfigService: FusionConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _lookupAPIClientService: LookupAPIClientService,
    private router: Router,
    public pageFacade: PageFacade,
    private userFacade: UserFacade,
    private orgFacade: OrgFacade,
    public dialog: MatDialog,
    private authService: AuthService,
    private messageService: MessageService,
    private ouNameService: OuNameService,
    private navigationService: FusionNavigationService,
  ) {
    this.messageService.getMessage().subscribe(() => {
      let tenantID = sessionStorage.getItem('tenantId');
      this.tenantName = sessionStorage.getItem('tenantName');
      let ouCode = sessionStorage.getItem('ouCode').toUpperCase();
      this.ouNameService
      .getOuNames(tenantID, ouCode, this.tenantName)
      .subscribe(res => {
        if(res.length > 0){
          let resp = res?.[0];
          let id = resp?.id?.split('.');
          this.ouCode = id[id?.length - 1];
          this.tenantID = resp?.tenantId;
          this._lookupAPIClientService.getSettingDictionary(
            this.tenantID,
            ouCode,
            this.tenantName
          ).subscribe(response => {
            GlobalVariable.settingDictionary = new Map(
              Object.entries(response)
            );
            this.navigationService.aliasLabels.next(
              GlobalVariable.settingDictionary
            );
          })
        }
        else if(res.length == 0){
          this.router.navigateByUrl(`NotFound`);
        }
      })
    })

    if (sessionStorage.getItem('LastLoginTime'))
      this.lastLoginTime = new Date(sessionStorage.getItem('LastLoginTime'));
    this.navigation = productNavigation;
    this._unsubscribeAll = new Subject();
    this.loggedInUser$ = this.userFacade.UserState$.pipe(
      takeUntil(this._unsubscribeAll)
    );
    this.hasDelegateRole$ = this.loggedInUser$.pipe(
      map((x) =>
        x?.user?.UserRoles?.some((y) => y.RoleCode.includes('Delegate.'))
      )
    );
    this.tenantId$ = this.orgFacade.OrgState$.pipe(
      takeUntil(this._unsubscribeAll),
      map((x) => x.TenantWithOuCodeTree?.TenantId)
    );
    this.selectedOucodeName$ = this.orgFacade.selectedOucode$.pipe(
      takeUntil(this._unsubscribeAll),
      map((x) => x?.Name)
    );

    this.pageFacade.pageTitle$
      .pipe(delay(0))
      .subscribe((x) => (this.pageTitle = x));

    // Set the private defaults
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fusionConfigService.uiSettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
  }
  /**
   * Toggle sidebar open
   *
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  signOut() {
    var isV3V4 = JSON.parse(sessionStorage.getItem('isV3V4'));
    this.userFacade.LogOut();
    sessionStorage.removeItem('StudentId');
    sessionStorage.clear();
    this.authService.redirectUrl =
      this._fusionConfigService.get('authGuardSettings').launchUrl;
    if (isV3V4)
      this.router.navigate([
        '/externalRedirect',
        {
          externalUrl:
            this._fusionConfigService.getservice('v3.login').endpoint,
        },
      ]);
    else this.router.navigateByUrl('/account/login');
    //this._drawerResolverService.closeDrawer('Successfully Logged Out');
  }

  openHelpPopup() {
    const dialogRef = this.dialog.open(HelpCenterPopupComponent, {
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openConsentDetailsPopup() {
    const dialogRef = this.dialog.open(ConsentDetailsPopupComponent, {
      width: '50vw',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

import { UserService } from './user.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HashDataModel, TenantWithOuCodeTree, NavigationChangeDetector,LaunchDataModel, OrgUnit, Organization } from '@exxat/fusion/models';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthSandbox {
  constructor(private readonly authService: AuthService, private readonly userService: UserService) { }

  public launch(): Observable<TenantWithOuCodeTree[]> {
    return this.authService.Launch('Base', '1000').pipe(catchError(error =>{
    console.log(`Your Token is not valid for launch api.${error}`);
        return of(null);
    }));
  }
  public role(hash: string): Observable<HashDataModel[]> {
    return this.authService.Role('Base', '1000', hash).pipe(catchError(error =>{
      console.log(`Your Token is not valid for role api.${error}`);
          return of(null);
      }));
  }

  public navigation(hash: string): Observable<NavigationChangeDetector> {
    return this.authService.Navigation('Base', '1000', hash).pipe(catchError(error =>{
      console.log(`Your Token is not valid for navigation api.${error}`);
          return of(null);
      }));
  }

  public getOrgUnit(id): Observable<OrgUnit> {
    return this.authService.getOrgUnit(id);
  }

  public getOrganization(id): Observable<Organization> {
      return this.authService.getOrganization(id);
  }
}


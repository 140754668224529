import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WelcomeMsg } from '@exxat/publicwebsite/models';
import { WelcomeMsgApiClient } from './welcomeMsg-api-client';

@Injectable()
export class WelcomeMsgSandbox {
  constructor(private welcomeMsgApiClient: WelcomeMsgApiClient) {}

  public getWelcomeMsg(
    tenantId: string,
    ouCode: string,
    tenantName: string
  ): Observable<WelcomeMsg> {
    return this.welcomeMsgApiClient.getWelcomeMSg(tenantId, ouCode, tenantName);
  }
}

export class ManifoldPanel {
  componentSelector: any;
  moduleId: string;
  caption: string;
  size: string;
  data: any;
  entityKey: any;
  primaryAction: string;
  secondaryAction: string;

  constructor(componentSelector, moduleId, caption, size, data, entityKey, primaryAction, secondaryAction) {
    this.componentSelector = componentSelector;
    this.moduleId = moduleId;
    this.caption = caption;
    this.size = size;
    this.data = data;
    this.entityKey = entityKey;
    this.primaryAction = primaryAction;
    this.secondaryAction = secondaryAction;
  }
}
